
@charset "utf-8";

.pagetitle{
	margin-top: 90px;
    margin-bottom: 110px;
    height: 400px;
    @include display-flex;
    @include justify-content-center;
    @include align-items-center;
    background-size: cover;
    background-position: center;
    position: relative;
    @include mq(w1600){
        margin-bottom: 8.5vw;
        height: 25vw;
    }
    @include mq(w1200){
        margin-bottom: 8.5vw;
        height: 20vw;
    }
    &::before{
        content: '';
        position: absolute;
        bottom: -35px;
        left: 50%;
        width: 1px;
        height: 35px;
        background-color: $cl-gray;
        display: block;
    }
    &::after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 1px;
        height: 35px;
        background-color: #fff;
        display: block;
    }
    @include mq(tb){
		margin-top: 70px;
        margin-bottom: 7vw;
        height: 22vw;
        &::before{
            bottom: -20px;
            height: 20px;
            display: block;
        }
        &::after{
            height: 20px;
        }
    }
    @include mq(sp){
		margin-top: 60px;
        margin-bottom: 30px;
        height: 30vw;
        &::before{
            bottom: -15px;
            height: 15px;
            display: block;
        }
        &::after{
            height: 15px;
        }
    }
    &--business{
        background-image: url(../img/business/pagetitle_bg.jpg);
    }
    &--company{
        background-image: url(../img/company/pagetitle_bg.jpg);
    }
    &--guide{
        background-image: url(../img/guide/pagetitle_bg.jpg);
    }
    &--media{
        background-image: url(../img/media/pagetitle_bg.jpg);
    }
    &__text{
        padding: 28px 30px 35px;
        color: #fff;
        font-family: $f-mincho;
        font-size: 30px;
        font-weight: 300;
        letter-spacing: 0.15em;
        line-height: 1.2;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        @include mq(w1600){
            font-size: 1.875vw;
        }
        @include mq(w1200){
            font-size: 28px;
            -webkit-writing-mode: horizontal-tb;
            -ms-writing-mode: lr-tb;
            -o-writing-mode: horizontal-tb;
            writing-mode: horizontal-tb;
        }
        @include mq(tb){
            font-size: 24px;
        }
        @include mq(sp){
            font-size: 5.2vw;
        }
    }
}

.pagetitle02{
	margin-top: 90px;
    margin-bottom: 80px;
	padding: 90px 80px 0 0;
	width: 100%;
	height: 520px;
	text-align: right;
	background-color: #000;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 100% 0;
    @include mq(w1600){
		padding: 5.625vw 5vw 5.625vw 0;
        margin-bottom: 5.625vw;
        height: auto;
    }
    @include mq(w1400){
		padding: 4.2vw 4.5vw 4.2vw 0;
    }
    @include mq(tb){
		margin-top: 70px;
        margin-bottom: 4vw;
    }
    @include mq(sp){
		margin-top: 60px;
        margin-bottom: 30px;
    }
	&--about{
		background-image: url(../img/about/pagetitle_bg.jpg);
	}
	&--tea{
		background-image: url(../img/tea/pagetitle_bg.jpg);
	}
    &__text-wrapper{
		width: 300px;
		float: right;
    	@include mq(sp){
			width: 100%;
			display: block;
			float: none;
		}
		&__copy{
			margin-left: 35px;
			font-size: 30px;
			line-height: 1;
			color: #fff;
			font-family: $f-mincho;
			font-weight: 400;
			-ms-writing-mode: tb-rl;
			writing-mode: vertical-rl;
			text-align: left;
			letter-spacing: 0.1em;
			display: inline-block;
			float: right;
    		@include mq(tb){
				margin-left: 25px;
				font-size: 23px;
			}
    		@include mq(sp){
				margin-left: 15px;
				font-size: 16px;
				display: block;
			}
		}
		&__text{
			font-size: 16px;
			line-height: 2.875;
			color: #fff;
			font-family: $f-mincho;
			font-weight: 400;
			-ms-writing-mode: tb-rl;
			writing-mode: vertical-rl;
			text-align: left;
			display: inline-block;
			float: right;
    		@include mq(w1400){
				height: 295px;
				text-align: justify;
			}
    		@include mq(tb){
				height: 230px;
				font-size: 15px;
				line-height: 2.6;
			}
    		@include mq(sp){
				width: 190px;
				height: 155px;
				font-size: 12px;
				line-height: 2.2;
				float: right;
				display: block;
			}
		}
    }
}

.title-circle{
    margin-bottom: 20px;
    padding-bottom: 38px;
    padding-left: 0.08em;
    font-size: 34px;
    line-height: 1.01;
    color: $cl-black;
    font-family: $f-mincho;
    font-weight: 400;
    letter-spacing: 0.08em;
    text-align: center;
    position: relative;
    &::before{
        content: '';
        left: calc( 50% - 10px );
        bottom: 0;
        width: 20px;
        height: 20px;
        border: 2px solid $cl-bg-d-gray;
        box-sizing: border-box;
        @include border-radius( 50% );
        position: absolute;
    }
    @include mq(tb){
        padding-bottom: 34px;
        font-size: 30px;
        &::before{
            content: '';
            left: calc( 50% - 9px );
            width: 18px;
            height: 18px;
        }
    }
    @include mq(sp){
        margin-bottom: 16px;
        padding-bottom: 26px;
        font-size: 22px;
        &::before{
            content: '';
            left: calc( 50% - 8px );
            width: 16px;
            height: 16px;
        }
    }
	&--gray{
		&::before{
			border-color: #abc;
		}
	}
	&--green{
		&::before{
			border-color: #a8af86;
		}
	}
	&__sub{
		padding-left: 0.15em;
		margin-top: 18px;
		font-size: 0.55em;
		line-height: 1.01;
		color: #789;
		letter-spacing: 0.15em;
		display: block;
    	@include mq(sp){
			margin-top: 10px;
			padding-left: 0.05em;
			letter-spacing: 0.05em;
		}
	}
}

.title-icon{
    margin-bottom: 12px;
    padding-left: 30px;
    font-size: 17px;
    line-height: 22px;
    color: $cl-black;
    font-family: $f-mincho;
    font-weight: 400;
    background-image: url(../img/common/icon_kyusu.svg);
    background-repeat: no-repeat;
    background-size: 22px auto;
    background-position: 0 0;
    @include mq(sp){
        margin-bottom: 10px;
        padding-left: 24px;
        font-size: 16px;
        line-height: 18px;
        background-size: 18px auto;
    }
}