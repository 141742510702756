
@charset "utf-8";

.guide-wrapper{
    padding: 0 40px 0;
    @include mq(tb){
        padding: 0 40px 0;
    }
    @include mq(sp){
        padding: 0 20px 0;
    }
	&__lead-wrapper{
		margin: 0 auto 60px;
		width: 100%;
		max-width: 860px;
		@include mq(tb){
			margin-bottom: 30px;
		}
		&__copy{
			margin-bottom: 25px;
			padding-left: 0.08em;
			font-size: 30px;
			line-height: 1.01;
			color: $cl-black;
			font-family: $f-mincho;
			font-weight: 400;
			letter-spacing: 0.08em;
			text-align: center;
			@include mq(tb){
				margin-bottom: 20px;
				font-size: 26px;
			}
			@include mq(sp){
				padding-left: 0;
				margin-bottom: 7px;
				font-size: 5.3vw;
				line-height: 1.5;
				letter-spacing: 0;
			}
		}
		&__text{
			font-size: 16px;
			line-height: 2.2;
			color: $cl-black;
			text-align: justify;
			@include mq(tb){
				font-size: 15px;
				line-height: 2.0;
			}
			@include mq(sp){
				font-size: 14px;
				line-height: 1.9;
			}
		}
	}
	&__photos{
		margin: 0 auto 120px;
		width: 100%;
		max-width: 1600px;
        @include mq(tb){
            margin-bottom: 60px;
        }
        @include mq(sp){
            margin: 0 -16px 30px;
			width: auto;
        }
		&__photo01{
			width: 50%;
			background-image: url(../img/guide/photo01.jpg);
			background-size: cover;
			background-position: 50% 50%;
			background-repeat: no-repeat;
			float: left;
			@include mq(sp){
				width: 100%;
				height: auto;
				float: none;
				&::before{
					content: '';
					padding-top: 70%;
					display: block;
				}
			}
		}
		&__right{
			width: 50%;
			float: right;
			@include mq(sp){
				width: 100%;
				float: none;
			}
			&__item{
				margin-left: 10px;
				width: calc( 50% - 10px );
				float: left;
				&:nth-child(n+3){
					margin-top: 10px;
				}
				@include mq(sp){
					margin-top: 4px;
					margin-left: 0;
					width: calc( 50% - 2px );
					&:nth-child(n+3){
						margin-top: 4px;
					}
					&:nth-child(even){
						float: right;
					}
				}
				img{
					width: 100%;
				}
			}
		}
	}
	&__inner{
		margin: 0 auto 50px;
		width: 100%;
		max-width: 1000px;
		@include mq(tb){
			margin-bottom: 40px;
		}
		@include mq(sp){
			margin-bottom: 30px;
		}
	}
	&__copy{
		margin-bottom: 40px;
		font-size: 18px;
		line-height: 2.4;
		color: $cl-black;
		font-family: $f-mincho;
		font-weight: 400;
		text-align: justify;
		@include mq(tb){
			margin-bottom: 35px;
			font-size: 16px;
			line-height: 2;
		}
		@include mq(sp){
			margin-bottom: 15px;
			font-size: 14px;
			line-height: 1.8;
		}
	}
}

.guide-map{
	margin-bottom: calc( -9.5vw + 1px );
	width: 100%;
	height: 500px;
	position: relative;
	@include mq(tb){
		height: 400px;
	}
	@include mq(sp){
		margin-bottom: -39px;
		height: 360px;
	}
	iframe{
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		position: absolute;
	}
}

.guide-access{
	margin-bottom: 120px;
	padding: 0 40px 0;
	@include mq(tb){
		margin-bottom: 60px;
		padding: 0 40px 0;
	}
	@include mq(sp){
		margin-bottom: 4px;
		padding: 0 4px 0;
	}
	&__inner{
		margin: 0 auto;
		padding: 70px;
		width: 100%;
		max-width: 1000px;
		background-color: $cl-bg-gray;
		@include mq(tb){
			padding: 40px;
		}
		@include mq(sp){
			padding: 25px 20px 20px;
		}
		&__title{
			margin-bottom: 40px;
			font-size: 28px;
			line-height: 1.01;
			color: $cl-black;
			font-family: $f-mincho;
			font-weight: 400;
			text-align: center;
			@include mq(tb){
				margin-bottom: 20px;
				font-size: 24px;
			}
			@include mq(sp){
				margin-bottom: 20px;
				font-size: 22px;
			}
		}
		&__icon{
			padding-top: 160px;
			width: 150px;
			font-size: 17px;
			line-height: 1.01;
			color: $cl-black;
			font-family: $f-mincho;
			font-weight: 400;
			text-align: center;
			float: left;
			position: relative;
			&::before{
				content: '';
				top: 0;
				left: calc( 50% - 70px );
				width: 140px;
				height: 140px;
				background-color: #fff;
				background-image: url(../img/common/icon_car.svg);
				background-size: 47% auto;
				background-repeat: no-repeat;
				background-position: 50% 50%;
				border-radius: 50%;
				position: absolute;
			}
			@include mq(tb){
				padding-top: 130px;
				width: 130px;
				font-size: 15px;
				&::before{
					left: calc( 50% - 60px );
					width: 120px;
					height: 120px;
				}
			}
			@include mq(sp){
				margin: 0 auto 10px;
				padding-top: 110px;
				font-size: 16px;
				float: none;
				&::before{
					left: calc( 50% - 50px );
					width: 100px;
					height: 100px;
				}
			}
		}
		&__detail{
			padding-top: 10px;
			width: calc( 100% - 210px );
			float: right;
			@include mq(tb){
				width: calc( 100% - 160px );
			}
			@include mq(sp){
				padding-top: 0;
				width: auto;
				float: none;
			}
			&__text{
				margin-bottom: 45px;
				font-size: 15px;
				line-height: 1.73;
				color: $cl-black;
				text-align: justify;
				@include mq(tb){
					margin-bottom: 30px;
				}
				@include mq(sp){
					margin-bottom: 15px;
					font-size: 14px;
				}
			}
			&__gifure{
				width: 100%;
				@include mq(sp){
					padding: 15px 20px;
					background-color: #fff;
				}
				img{
					width: 100%;
				}
			}
		}
	}
}