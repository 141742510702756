@charset "utf-8";

/*--------------------------------------------------------

	リセット : html5reset-1.6.1.css

参考
https://coliss.com/articles/build-websites/operation/css/my-css-reset-by-ire.html
----------------------------------------------------------*/

/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/

html{
    font-size: 62.5%;
}

/*body設定*/
body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", HelveticaNeue, YuGothic, "Yu Gothic", Verdana, Meiryo, sans-serif;
    color: #000;
    -webkit-text-size-adjust: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #fff;
    line-height: 1.7;
    font-size: 1.6rem;
    overflow-x: hidden;
    text-rendering: optimizeSpeed;
    @include mq(pc) {
        font-weight: 500;
    }
    /*任意でフォントサイズを指定*/
    @include mq(tb) {
        font-size: 1.5rem;
    }
    @include mq(sp) {
        font-size: 1.4rem;
    }
}

/*IE用：游ゴシックの謎余白をなくすためフォントを別指定*/
@media all and (-ms-high-contrast:none) {
    body {
        font-family: Meiryo, sans-serif;
    }
}

/*要素のフォントサイズやマージン・パディングをリセットしています*/
html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
span,
em,
small,
strong,
sub,
sup,
mark,
del,
ins,
strike,
abbr,
dfn,
blockquote,
q,
cite,
code,
pre,
ol,
ul,
li,
dl,
dt,
dd,
div,
section,
article,
main,
aside,
nav,
header,
hgroup,
footer,
img,
figure,
figcaption,
address,
time,
audio,
video,
canvas,
iframe,
details,
summary,
fieldset,
form,
label,
legend,
caption
 {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    vertical-align: baseline;
    background: transparent;
    box-sizing: border-box;
}

/*テーブル指定*/
table,
tbody,
tfoot,
thead,
tr,
th,
td{
    margin: 0;
    padding: 0;
    vertical-align: baseline;
    background: transparent;
    box-sizing: border-box;
}
table{
    border-collapse: collapse;
    border-spacing: 0;
}

/*新規追加要素のデフォルトはすべてインライン要素になっているので、section要素などをブロック要素へ変更しています*/
main,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

/*nav要素内ulのマーカー（行頭記号）を表示しないようにしています marginを指定したら地獄*/
ul,
ol{
    list-style: none;
}

/*引用符の表示が出ないようにしています*/
blockquote,
q {
    quotes: none;
}

/*blockquote要素、q要素の前後にコンテンツを追加しないように指定しています*/
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

/* ins要素のデフォルトをセットし、色を変える場合はここで変更できるようにしています */
ins {
    background-color: #ff9;
    color: #000;
    text-decoration: none;
}

/* mark要素のデフォルトをセットし、色やフォントスタイルを変える場合はここで変更できるようにしています。また、mark要素とは、文書内の検索結果で該当するフレーズをハイライトして、目立たせる際に使用するようです。*/
mark {
    background-color: #ff9;
    color: #000;
    font-style: italic;
    font-weight: bold;
}

/*テキストに打ち消し線が付くようにしています*/
del {
    text-decoration: line-through;
}

/*IEではデフォルトで点線を下線表示する設定ではないので、下線がつくようにしています。また、マウスオーバー時にヘルプカーソルの表示が出るようにしています*/
abbr[title],
dfn[title] {
    border-bottom: 1px dotted;
    cursor: help;
}

/*水平罫線のデフォルトである立体的な罫線を見えなくしています*/
hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #cccccc;
    margin: 1em 0;
    padding: 0;
}

/*縦方向の揃え位置を中央揃えに指定しています*/
input,
select {
    vertical-align: middle;
}

/*画像を縦に並べたときに余白が出てしまわないように*/
img {
    vertical-align: top;
    font-size: 0;
    line-height: 0;
    -webkit-backface-visibility: hidden;
    width: auto;
    max-width: 100%;
    height: auto;
}

/* タッチデバイスでのリンクやボタンの反応を向上 */
a,
area,
button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
}

//プリントcss
@mixin print {
    @media print {
        @content;
    }
}

@page {
    size: A4;
    margin: 5mm;
}

/*a要素のフォントサイズなどをリセットしフォントの縦方向の揃え位置を親要素のベースラインに揃えるようにしています*/
a {
    margin: 0;
    padding: 0;
    vertical-align: baseline;
    background: transparent;
    transition: 0.2s;
    text-decoration: none;
}

/*クラスの指定がない場合のリンクのデフォルト色を指定*/
a:not([class]) {
    color: #77b627;
}

a:not([class]):hover {
    color: #51880c;
}

a:not([class]):active {
    color: #51880c;
}

/*すべての要素のfont-family, font-size, line-heightをその親から継承するようにリセット*/
* {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}


/*属性と状態のCSSリセット*/
[hidden] {
    display: none !important;
}

[disabled] {
    cursor: not-allowed;
}

:focus:not(:focus-visible) {
    outline: none;
}

/*box-sizing*/
*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}

/*clearfix*/
/* For modern browsers */
.clearfix::after {
    content: "";
    display: table;
    clear: both;
}

/* For IE 6/7 only */
.clearfix {
    *zoom: 1;
}

@mixin clearfix {
    *zoom: 1;
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

/*禁則処理の追加*/
p,
li,
dt,
dd,
th,
td,
pre {
    -ms-line-break: strict;
    line-break: strict;
    -ms-word-break: break-strict;
    word-break: break-strict
}

/** Selection */
::-moz-selection,
::selection {
    background-color: #b3d4fc;
    /* Change as appropriate */
    color: #000;
    /* Change as appropriate */
    text-shadow: none;
}

//視差効果
@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}
