
@charset "utf-8";

.business-lead{
    padding: 0 40px 80px;
    @include mq(tb){
        padding: 0 40px 60px;
    }
    @include mq(sp){
        padding: 0 20px 30px;
    }
	&__copy{
		margin-bottom: 25px;
		font-size: 34px;
		line-height: 1.3;
		color: $cl-black;
		font-family: $f-mincho;
		font-weight: 400;
		text-align: center;
		@include mq(tb){
			margin-bottom: 15px;
			font-size: 30px;
		}
		@include mq(sp){
			margin-bottom: 5px;
			font-size: 5.3vw;
		}
	}
	&__text{
		font-size: 16px;
		line-height: 2.2;
		color: $cl-black;
		text-align: center;
		@include mq(tb){
			margin: 0 auto;
			width: 100%;
			max-width: 600px;
			font-size: 15px;
			line-height: 2.0;
			text-align: justify;
		}
		@include mq(sp){
			font-size: 14px;
			line-height: 1.9;
		}
	}
}

.business-ex{
	padding: 80px 40px;
	background-color: $cl-bg-d-gray;
	@include mq(tb){
		padding: 40px;
	}
	@include mq(sp){
		padding: 35px 2px 2px;
	}
	&__inner{
		margin: 0 auto;
		width: 100%;
		max-width: 1690px;
		display: flex;
		align-items: stretch;
		justify-content: space-between;
        @include mq(tb){
			margin-top: -30px;
			flex-wrap: wrap;
		}
        @include mq(sp){
			margin-top: -2px;
		}
		&__item{
			padding: 40px 30px 30px;
			width: calc( ( 99.9% - 90px ) / 4 );
			background-color: #fff;
            @include mq(w1300){
                padding: 30px 20px 20px;
				width: calc( ( 99.9% - 60px ) / 4 );
            }
            @include mq(tb){
				margin-top: 30px;
                padding: 40px 30px 30px;
				width: calc( 50% - 15px );
            }
            @include mq(sp){
				margin-top: 2px;
                padding: 20px 5px 5px;
				width: calc( 50% - 1px );
            }
			&__title{
				margin-bottom: 15px;
				padding-left: 0.08em;
				padding-top: 120px;
				font-size: 22px;
				line-height: 1.01;
				color: $cl-black;
				font-family: $f-mincho;
				font-weight: 400;
				text-align: center;
				background-repeat: no-repeat;
				@include mq(w1300){
					font-size: 1.7vw;
				}
				@include mq(tb){
					font-size: 21px;
				}
				@include mq(sp){
					margin-bottom: 10px;
					padding-top: calc( 120px * 0.6 );
					font-size: 14px;
				}
				&--01{
					background-image: url(../img/business/ex1.svg);
					background-size: 79px auto;
					background-position: 50% 0;
					@include mq(sp){
						background-size: calc( 79px * 0.6 ) auto;
					}
				}
				&--02{
					background-image: url(../img/business/ex2.svg);
					background-size: 74px auto;
					background-position: 50% 0;
					@include mq(sp){
						background-size: calc( 74px * 0.6 ) auto;
					}
				}
				&--03{
					background-image: url(../img/business/ex3.svg);
					background-size: 119px auto;
					background-position: 50% 10px;
					@include mq(sp){
						background-position: 50% 6px;
						background-size: calc( 119px * 0.6 ) auto;
					}
				}
				&--04{
					background-image: url(../img/business/ex4.svg);
					background-size: 108px auto;
					background-position: 50% 0;
					@include mq(sp){
						background-size: calc( 108px * 0.6 ) auto;
					}
				}
			}
			&__list{
				margin: 0 auto;
				width: 100%;
				max-width: 300px;
				height: 120px;
				text-align: center;
				background-color: #f7f7f7;
				position: relative;
                @include mq(sp){
                    height: 80px;
                }
				&__inner{
					top: 50%;
					left: 0;
					width: 100%;
					text-align: center;
					transform: translateY( -50% );
					position: absolute;
					&__item{
						font-size: 15px;
						line-height: 1.8;
						color: $cl-black;
						text-align: center;
						@include mq(w1200){
							font-size: 14px;
						}
						@include mq(sp){
							font-size: 11px;
							line-height: 1.6;
						}
						@include mq(w320){
							font-size: 10px;
						}
					}
				}
			}
		}
	}
}

.business-shisatsu{
	padding: 60px;
	@include mq(w1400){
		padding: 4.2vw;
	}
	@include mq(tb){
		padding: 40px;
	}
	@include mq(sp){
		padding: 0;
	}
	&__inner{
		background-color: #d9e0b7;
		&__text-wrapper{
			width: 50%;
			padding: 120px 60px 130px;
			float: right;
			@include mq(w1500){
				padding: 7vw 4vw 7.5vw;
			}
			@include mq(tb){
				padding: 60px 40px 40px;
				width: 100%;
				float: none;
			}
			@include mq(sp){
				padding: 35px 20px 20px;
			}
			&__text{
				margin: 0 auto;
				width: 100%;
				max-width: 540px;
				font-size: 18px;
				line-height: 2.3;
				color: $cl-black;
				font-family: $f-mincho;
				font-weight: 400;
				text-align: justify;
				@include mq(w1400){
					font-size: 16px;
				}
				@include mq(tb){
					line-height: 2.0;
					text-align: justify;
				}
				@include mq(sp){
					font-size: 14px;
					line-height: 1.9;
				}
			}
		}
		&__photo{
			width: 50%;
			float: left;
			background-image: url(../img/business/photo01.jpg);
			background-repeat: no-repeat;
			background-size: cover;
			background-position: 50% 50%;
			@include mq(tb){
				width: 100%;
				float: none;
				&::before{
					content: '';
					padding-top: 50%;
					display: block;
				}
			}
		}
	}
}

.faq-wrapper{
	padding: 120px 40px 0;
	@include mq(tb){
		padding: 60px 40px 0;
	}
	@include mq(sp){
		padding: 35px 20px 0;
	}
}

.faq-list{
	margin: 0 auto;
	width: 100%;
	max-width: 900px;
    &__item{
        margin-top: 40px;
        padding-top: 40px;
        border-top: 1px dotted #bbb;
		&:first-child{
			padding-top: 0;
			border-top: none;
		}
		@include mq(tb){
			margin-top: 30px;
			padding-top: 30px;
		}
		@include mq(sp){
			margin-top: 20px;
			padding-top: 20px;
			
			&:first-child{
				margin-top: 0;
				padding-top: 0;
				border-top: none;
			}
		}
		&__q{
			margin-bottom: 15px;
			@include mq(sp){
				margin-bottom: 10px;
			}
			&__icon{
				padding: 10px 0 12px;
				width: 40px;
				font-size: 20px;
				line-height: 1.01;
				color: #fff;
				font-family: $f-lato;
				font-weight: 700;
				text-align: center;
				vertical-align: middle;
				background-color: $cl-black;
				display: table-cell;
				@include mq(sp){
					padding: 7px 0 10px;
					width: 35px;
					font-size: 17px;
				}
			}
			&__text{
				padding-left: 15px;
				width: calc( 100% - 40px );
				font-size: 20px;
				line-height: 1.4;
				color: $cl-black;
				font-family: $f-mincho;
				font-weight: 400;
				text-align: justify;
				vertical-align: middle;
				display: table-cell;
				@include mq(sp){
					padding-left: 10px;
					width: calc( 100% - 35px );
					font-size: 17px;
				}
			}
		}
		&__a{
			padding: 30px;
			font-size: 16px;
			line-height: 2.0;
			color: $cl-black;
			text-align: justify;
			background-color: #f3f3f3;
            @include mq(sp){
                padding: 20px;
				font-size: 14px;
				line-height: 1.8;
            }
		}
    }
}