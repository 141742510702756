@charset "utf-8";


/*クリアフィックス*/

.clearfix {
    *zoom: 1;
    &:after {
      content: '';
      display: block;
      clear: both;
      height: 0;
      line-height: 0;
      visibility: hidden;
    }
}

a{
    text-decoration: none;
    transition: 0.5s;
    -ms-transition: 0.5s;
}

p,h1,h2,h3,h4,h5,dt,dd,a{
font-display: swap;
}

/*マージン*/
.mb00 {
    margin-bottom: 0px !important;
}

.mb20 {
    margin-bottom: 20px !important;
    @include mq(sp){
        margin-bottom: 10px !important;
    }
}

.mb100 {
    margin-bottom: 100px;
    @include mq(sp){
        margin-bottom: 10px !important;
    }
}

.mlr-05{
    margin-left: -0.5em;
    margin-right: -0.5em;
}

.mlr-02{
    margin-left: -0.2em;
    margin-right: -0.2em;
}

.ml-05{
    margin-left: -0.5em;
}

.ml-02{
    margin-left: -0.2em;
}

.mr-02{
    margin-right: -0.2em;
}

.anchor{
    margin-top: -80px;
    padding-top: 80px;
    @include mq(tb){
        margin-top: -70px;
        padding-top: 70px;
    }
    @include mq(sp){
        margin-top: -60px;
        padding-top: 60px;
    }
}