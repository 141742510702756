
@charset "utf-8";


.contact-wrapper {
	padding: 80px 40px;
	background-image: url(../img/common/contact_bg.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 50%;
	@include mq(tb){
		padding: 40px;
	}
	@include mq(sp){
		padding: 20px;
	}
	&__inner{
		margin: 0 auto;
		padding: 45px 40px 50px;
		width: 100%;
		max-width: 700px;
		text-align: center;
		background-color: #fff;
		@include mq(sp){
			padding: 25px 20px 20px;
		}
		&__copy{
			margin-bottom: 20px;
			font-size: 18px;
			line-height: 1.65;
			color: $cl-black;
			font-family: $f-mincho;
			font-weight: 400;
			text-align: center;
			@include mq(sp){
				margin-bottom: 10px;
				font-size: 14px;
				text-align: justify;
			}
			&--l{
				padding-left: 0.08em;
				font-size: 24px;
				letter-spacing: 0.08em;
				@include mq(sp){
					margin-bottom: 13px;
					padding-left: 0.02em;
					font-size: 18px;
					line-height: 1.4;
					letter-spacing: 0.02em;
					text-align: center;
				}
			}
		}
		&__title{
			margin-bottom: 10px;
			font-size: 16px;
			line-height: 1.01;
			color: $cl-black;
			font-family: $f-noto;
			font-weight: 700;
			text-align: center;
			@include mq(sp){
				margin-bottom: 8px;
				font-size: 15px;
			}
		}
		&__tel{
			padding-left: 54px;
			font-size: 48px;
			line-height: 1.01;
			color: $cl-black;
			font-family: $f-lato;
			font-weight: 700;
			background-image: url(../img/common/icon_freedial.svg);
			background-repeat: no-repeat;
			background-size: 43px auto;
			background-position: 0 50%;
			display: inline-block;
			cursor: auto;
			@include mq(sp){
				padding-left: calc( 54px * 0.7 );
				font-size: 34px;
				background-size: calc( 43px * 0.7 ) auto;
				@include mq(w320){
					font-size: 30px;
				}
			}
		}
		&__time{
			margin: 15px 0 30px;
			font-size: 16px;
			line-height: 1.01;
			color: $cl-black;
			font-family: $f-noto;
			font-weight: 700;
			text-align: center;
			@include mq(sp){
				margin: 8px 0 15px;
				font-size: 14px;
				line-height: 1.5;
			}
		}
	}
}
