
@charset "utf-8";

.company-outline{
    padding: 0 40px 120px;
    @include mq(tb){
        padding: 0 40px 60px;
    }
    @include mq(sp){
        padding: 0 20px 30px;
    }
	&__inner{
		margin: 0 auto;
		width: 100%;
		max-width: 1000px;
	}
}

.company-greeting{
    &__photo{
        width: 37%;
		background-image: url(../img/company/photo01.jpg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 0;
		float: left;
		@include mq(w1200){
			width: 100%;
			background-position: 50% 20%;
			float: none;
			&::before{
				content: '';
				padding-top: 55%;
				display: block;
			}
		}
    }
    &__text-wrapper{
		padding: 130px 50px;
        width: 63%;
        background-color: $cl-bg-d-gray;
		float: right;
		@include mq(w1600){
			padding: 7vw 50px;
		}
		@include mq(w1200){
			padding: 100px 50px 100px;
			width: 100%;
			float: none;
		}
		@include mq(tb){
			padding: 70px 50px 60px;
		}
		@include mq(sp){
			padding: 30px 20px 30px;
		}
		&__inner{
			margin: 0 auto;
			width: 100%;
			max-width: 730px;
			height: 360px;
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			flex-direction: row-reverse;
			@include mq(w1400){
				max-width: 670px;
			}
			@include mq(w1200){
				max-width: 730px;
				height: 360px;
			}
			@include mq(sp){
				max-width: 100%;
				height: auto;
				display: block;
			}
		}
		&__title{
			font-size: 34px;
			letter-spacing: 0.1em;
            font-family: $f-mincho;
            font-weight: 400;
            text-align: left;
            -webkit-writing-mode: vertical-rl;
            -ms-writing-mode: tb-rl;
            -o-writing-mode: vertical-rl;
            writing-mode: vertical-rl;
			@include mq(tb){
				font-size: 27px;
			}
			@include mq(sp){
				margin-bottom: 15px;
				font-size: 22px;
				text-align: center;
                -webkit-writing-mode: horizontal-tb;
                -ms-writing-mode: lr-tb;
                -o-writing-mode: horizontal-tb;
                writing-mode: horizontal-tb;
			}
		}
		&__text{
			font-size: 17px;
			line-height: 2.5;
			color: $cl-black;
			font-family: $f-mincho;
			font-weight: 400;
            -webkit-writing-mode: vertical-rl;
            -ms-writing-mode: tb-rl;
            -o-writing-mode: vertical-rl;
            writing-mode: vertical-rl;
			text-align: justify;
			@include mq(w1400){
				font-size: 16px;
				line-height: 2.4;
			}
			@include mq(w1200){
				font-size: 17px;
				line-height: 2.5;
			}
			@include mq(tb02){
				font-size: 16px;
				line-height: 2.5;
			}
			@include mq(sp){
				margin-bottom: 15px;
				width: auto;
				font-size: 14px;
				line-height: 1.9;
				text-align: justify;
                -webkit-writing-mode: horizontal-tb;
                -ms-writing-mode: lr-tb;
                -o-writing-mode: horizontal-tb;
                writing-mode: horizontal-tb;
			}
		}
		&__writer{
			font-size: 15px;
			line-height: 2.2;
			color: $cl-black;
			font-family: $f-mincho;
			font-weight: 400;
			letter-spacing: 0.08em;
            -webkit-writing-mode: vertical-rl;
            -ms-writing-mode: tb-rl;
            -o-writing-mode: vertical-rl;
            writing-mode: vertical-rl;
			@include mq(w1400){
				font-size: 14px;
				line-height: 2.0;
			}
			@include mq(tb){
				font-size: 13px;
				line-height: 1.9;
			}
			@include mq(sp){
				font-size: 12px;
				line-height: 1.5;
				text-align: left;
                -webkit-writing-mode: horizontal-tb;
                -ms-writing-mode: lr-tb;
                -o-writing-mode: horizontal-tb;
                writing-mode: horizontal-tb;
			}
			&__name{
				margin-top: 0.5em;
				font-size: 1.5em;
				@include mq(sp){
					margin-top: 0;
					margin-left: 0.5em;
					font-size: 1.5em;
				}
			}
		}
    }
}

.company-history{
    padding: 120px 40px;
	@include mq(tb){
		padding: 60px 40px;
	}
	@include mq(sp){
		padding: 30px 20px;
	}
	&__inner{
		margin: 0 auto;
		width: 100%;
		max-width: 1000px;
		position: relative;
		&::before{
			content: '';
			top: 8px;
			left: 50%;
			width: 1px;
			height: calc( 100% - 7px );
			background-color: #ddd;
			position: absolute;
			z-index: -1;
			@include mq(sp){
				left: 0;
			}
		}
		&__item{
			margin-left: 50%;
			margin-top: 50px;
			padding-left: 40px;
			width: 50%;
			position: relative;
			@include mq(tb){
				padding-left: 20px;
				margin-top: 30px;
			}
			@include mq(sp){
				margin-left: 0;
				margin-top: 20px;
				padding-left: 20px;
				width: 100%;
			}
			&::before{
				content: '';
				top: 6px;
				left: -3px;
				width: 7px;
				height: 7px;
				background-color: $cl-black;
				border-radius: 50%;
				position: absolute;
			}
			&:nth-child(even){
				margin-left: 0;
				margin-right: 50%;
				padding-left: 0;
				padding-right: 40px;
				text-align: right;
				@include mq(tb){
					padding-right: 20px;
				}
				@include mq(sp){
					padding-right: 0;
					padding-left: 20px;
					text-align: left;
				}
				&::before{
					content: '';
					left: auto;
					right: -4px;
					@include mq(sp){
						left: -3px;
						right: auto;
					}
				}
				.company-history__inner__item__text{
					margin-right: -0.5em;
					@include mq(sp){
						margin-right: 0;
					}
				}
			}
			&:first-child{
				margin-top: 0;
			}
			&__year{
				margin-bottom: 10px;
				font-size: 20px;
				line-height: 1.01;
				color: $cl-black;
				font-family: $f-mincho;
				font-weight: 400;
                @include mq(sp){
					margin-bottom: 5px;
					font-size: 17px;
                }
			}
			&__text{
				font-size: 15px;
				line-height: 1.7;
				color: $cl-black;
				font-family: $f-mincho;
				font-weight: 400;
				@include mq(tb){
					font-size: 14px;
				}
				@include mq(sp){
					text-align: justify;
				}
			}
		}
	}
}

.company-factory{
    margin-bottom: calc( -9.5vw + 1px );
	@include mq(sp){
		margin-bottom: -39px;
	}
	&__photo{
		width: 50%;
		background-image: url(../img/company/photo02.jpg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 50%;
		float: right;
		@include mq(tb02){
			width: auto;
			float: none;
			&::before{
				content: '';
				padding-top: 50%;
				display: block;
			}
		}
	}
	&__text-wrapper{
		padding: 80px 40px;
		width: 50%;
		background-color: #123;
		float: left;
		@include mq(w1500){
			padding: 5.5vw 40px;
		}
		@include mq(tb){
			padding: 5vw;
		}
		@include mq(tb02){
			padding: 7vw;
			width: auto;
			float: none;
		}
		@include mq(sp){
			padding: 30px 20px 30px;
			width: auto;
			float: none;
		}
		&__inner{
			margin: 0 auto;
			width: 100%;
			max-width: 490px;
			&__title{
				font-size: 34px;
				line-height: 1.01;
				color: #fff;
				font-family: $f-mincho;
				font-weight: 400;
				letter-spacing: 0.08em;
				@include mq(tb){
					font-size: 24px;
				}
				@include mq(sp){
					font-size: 20px;
					text-align: center;
				}
			}
			&__title02{
				margin-top: 30px;
				margin-bottom: 15px;
				padding-left: 30px;
				font-size: 20px;
				line-height: 1.01;
				color: #fff;
				font-family: $f-mincho;
				font-weight: 400;
				letter-spacing: 0.08em;
				position: relative;
				&::before{
					content: '';
					top: 2px;
					left: 0;
					width: 17px;
					height: 17px;
					border: 1px solid #abc;
					border-radius: 50%;
					position: absolute;
				}
				@include mq(tb){
					margin-bottom: 20px;
					margin-bottom: 13px;
					padding-left: 22px;
					font-size: 17px;
					&::before{
						width: 14px;
						height: 14px;
					}
				}
				@include mq(sp){
					margin-top: 15px;
					margin-bottom: 10px;
					padding-left: 20px;
					font-size: 16px;
					&::before{
						content: '';
						top: 2px;
						width: 13px;
						height: 13px;
					}
				}
			}
			&__item{
				width: calc( 50% - 2px );
				height: 60px;
				font-size: 17px;
				line-height: 58px;
				color: #fff;
				text-align: center;
				background-color: #1a2e42;
				float: left;
				&:nth-child(even){
					float: right;
				}
				@include mq(w1200){
					height: 50px;
					font-size: 16px;
					line-height: 49px;
				}
				@include mq(w1100){
					font-size: 15px;
				}
				@include mq(tb){
					font-size: 14px;
				}
				@include mq(sp){
					height: 40px;
					font-size: 3.2vw;
					line-height: 39px;
				}
				&--1column{
					margin-top: 4px;
					width: 100%;
					float: none;
				}
			}
		}
	}
}
