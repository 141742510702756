
@charset "utf-8";

.header-wrapper{
    top: 0;
    left: 0;
    width: 100%;
    height: 90px;
    background-color: #fff;
    position: fixed;
    z-index: 10;
    box-sizing: border-box;
    @include mq(tb){
        height: 70px;
    }
    @include mq(sp){
        height: 60px;
    }
    &__logo{
        margin: 27px 0 0 30px;
        width: 220px;
        float: left;
        @include mq(w1300){
			margin: 27px 0 0 20px;
			width: 190px;
		}
        @include mq(tb){
            margin: 20px 0 0 15px;
            width: 190px;
        }
        @include mq(sp){
            margin: 17px 0 0 15px;
            width: 160px;
        }
        &__link{
            &:hover{
                opacity: 0.7;
                @include mq(tb){
                    opacity: 1;
                }
            }
        }
    }
    &__nav{
        margin-right: 115px;
        float: right;
        height: 90px;
        @include mq(w1400) {
            margin-right: 100px;
        }
        @include mq(tb) {
			margin-right: 85px;
            height: 70px;
        }
        &__item{
            margin-left: 35px;
            float: left;
            height: 100%;
            @include mq(w1400){
                margin-left: 20px;
            }
            @include mq(w1200){
                margin-left: 10px;
            }
            &:first-child{
                margin-left: 0;
            }
            &--sns{
                padding-top: 30px;
                margin-left: 16px;
                @include mq(w1400){
                    margin-left: 5px;
                }
                @include mq(tb){
                    padding-top: 22px;
                	margin-left: 15px;
                }
                @include mq(sp){
                    display: none;
                }
            }
            &__menu {
                @include display-flex;  
                @include align-items-center;
                height: 100%;
				@include mq(w1100){
					display: none;
				}
                li {
                    &:not(:last-child) {
                        margin-right: 30px;
                        @include mq(w1400) {
                            margin-right: 20px;
                        }
						@include mq(w1200){
							margin-right: 10px;
						}
                    }
                    a {
                        display: inline-block;
                        color: $cl-black;
                        font-family: $f-mincho;
                        font-weight: 700;
                        @include mq(w1400) {
                            font-size: 14px;
                        }
                        @include mq(w1200) {
                            font-size: 13px;
                        }
						&:hover{
							@include mq(pc){
								color: $cl-gray;
							}
						}
                    }
                    &.home {
                        a {
                            width: 18px;
                            height: 27px;
							background-image: url(../img/common/icon_home.svg);
							background-repeat: no-repeat;
							background-size: 100% auto;
							background-position: 50% 80%;
							@include mq(w1200) {
								width: calc( 18px * 0.8 );
                            	height: calc( 17px * 0.8 );
							}
							&:hover{
								@include mq(pc){
									background-image: url(../img/common/icon_home_r.svg);
								}
							}
                        }
                    }
                }         
            }
            &__btn-tel{
                padding-top: 23px;
                font-size: 24px;
                line-height: 1.01;
                color: $cl-black;
                font-family: $f-lato;
                font-weight: 700;
                display: block;
                cursor: text;
                @include mq(tb){
                    padding-top: 17px;
                    font-size: 20px;
                }
                @include mq(sp){
                    top: 0;
                    right: 60px;
                    padding-top: 0;
                    width: 60px;
                    height: 60px;
                    background-color: $cl-black;
                    background-image: url(../img/common/icon_tel.svg);
                    background-repeat: no-repeat;
                    background-size: 40% auto;
                    background-position: 50% 45%;
                    display: block;
                    position: absolute;
                }
                &__time{
                    margin-top: 3px;
                    font-size: 14px;
                    line-height: 1.01;
                    font-family: $f-noto;
                    font-weight: 700;
                    letter-spacing: 0.02em;
                    display: block;
                    @include mq(tb){
                        font-size: 12px;
                        letter-spacing: 0em;
                    }
                    @include mq(sp){
                        display: none;
                    }
                }
            }
            &__btn-sns{
                margin-left: 10px;
                width: 30px;
                height: 30px;
                background-color: $cl-black;
                background-repeat: no-repeat;
                @include border-radius( 50% );
                display: block;
                float: left;
                @include mq(tb){
                    margin-left: 5px;
                    width: 26px;
                    height: 26px;
                    &:first-child{
                        margin-left: 0;
                    }
                }
                @include mq(sp){
                    display: none;
                }
                &:hover{
                    background-color: $cl-gray;
                    @include mq(tb){
                        background-color: $cl-black;
                    }
                }
                &--line{
                    background-image: url(../img/common/icon_line.svg);
                    background-size: 60% auto;
                    background-position: 50% 50%;
                }
                &--insta{
                    background-image: url(../img/common/icon_insta.svg);
                    background-size: 52% auto;
                    background-position: 50% 50%;
                }
            }
        }
    }
    &__menu-wrapper{
        &__btn-menu{
            top: 0;
            right: 0;
            width: 90px;
            height: 90px;
            position: fixed;
            z-index: 21;
            background-color: $cl-bg-gray;
            cursor: pointer;
            transition: 0.5s;
            @include mq(tb){
                width: 70px;
                height: 70px;
                background-color: $cl-gray;
            }
            @include mq(sp){
                width: 60px;
                height: 60px;
            }
            &:hover{
                background-color: $cl-gray;
                span{
                    background-color: #fff;
                    &::before{
                        background-color: #fff;
                    }
                    &::after{
                        background-color: #fff;
                    }
                }
            }
            &::before{
                content: '';
                top: 90px;
                right: 0;
                width: 0;
                height: calc( 100vh - 90px );
                background-color: rgba( $cl-black,0.9 );
                @include box-sizing( border-box );
                position: absolute;
                transition: 0.5s;
                z-index: 100;
                display: block;
                z-index: -1;
                @include mq(tb){
                    top: 70px;
                    height: calc( 100vh - 70px );
                }
                @include mq(sp){
                    top: 60px;
                    height: calc( 100vh - 60px );
                }
            }
            @include mq(sp){
                width: 60px;
                height: 60px;
            }
            span{
                top: 44px;
                right: calc( 50% - 15px );
                width: 30px;
                height: 1px;
                background-color: $cl-black;
                position: absolute;
                transition: 0.5s;
                @include mq(tb){
                    top: 34px;
                    right: calc( 50% - 13px );
                    width: 26px;
                    background-color: #fff;
                }
                &::before{
                    content: '';
                    top: -8px;
                    right: 0;
                    width: 100%;
                    height: 1px;
                    background-color: $cl-black;
                    position: absolute;
                    transition: 0.5s;
                    @include mq(tb){
                        background-color: #fff;
                    }
                }
                &::after{
                    content: '';
                    bottom: -8px;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background-color: $cl-black;
                    position: absolute;
                    transition: 0.5s;
                    @include mq(tb){
                        background-color: #fff;
                    }
                }
                @include mq(sp){
                    top: 29px;
                    right: calc( 50% - 11px );
                    width: 22px;
                    &::before{
                        top: -6px;
                    }
                    &::after{
                        bottom: -6px;
                    }
                }
            }
        }
        &__inner{
            top: 90px;
            right: 0;
            width: 100vw;
            height: 0;
            background-color: rgba( $cl-black,0.9 );
            transition: 0.3s;
            @include box-sizing( border-box );
            position: fixed;
            z-index: 22;
            @include mq(tb){
                top: 70px;
            }
            @include mq(sp){
                top: 60px;
            }
            &::-webkit-scrollbar{
              width: 6px;
            }
            &::-webkit-scrollbar-track{
              background: #000;
              border: none;
              border-radius: 0;
              box-shadow: none; 
            }
            &::-webkit-scrollbar-thumb{
              background: #333;
              border-radius: 3px;
              box-shadow: none;
            }
        }
    }
    .btn-input{
        display: none;
    }
    input[type=checkbox].btn-input:checked + .header-wrapper__menu-wrapper{
        .header-wrapper__menu-wrapper__inner{
            height: calc( 100vh - 90px );
            overflow-y: scroll;
            @include mq(tb){
                height: calc( 100vh - 70px );
            }
            @include mq(sp){
                height: calc( 100vh - 60px );
            }
        }
        .header-wrapper__menu-wrapper__btn-menu{
            background-color: $cl-gray;
            span{
                background-color: #fff;
                &::before{
                    width: 0;
                    background-color: #fff;
                }
                &::after{
                    width: 0;
                    background-color: #fff;
                }
            }
        }
		.menu-inner{
			display: block;
		}
    }
}
