
@charset "utf-8";

.media-lead{
    padding: 0 40px 80px;
    @include mq(tb){
        padding: 0 40px 30px;
    }
    @include mq(sp){
        padding: 0 20px 30px;
    }
	&__copy{
		font-size: 22px;
		line-height: 2.0;
		color: #262;
		font-family: $f-mincho;
		font-weight: 400;
		text-align: center;
		@include mq(tb){
			font-size: 18px;
		}
		@include mq(sp){
			font-size: 14px;
			line-height: 1.8;
			text-align: justify;
		}
	}
}

.media-wrapper{
	&__item{
		padding: 100px 60px;
		background-color: $cl-bg-gray;
		@include mq(tb){
			padding: 40px;
		}
		@include mq(sp){
			padding: 20px 20px 30px;
		}
		&:nth-child(even){
			background-color: #fff;
			.media-wrapper__item__text-wrapper__icon{
				background-color: $cl-bg-gray;
			}
		}
		&__inner{
			margin: 0 auto;
			width: 100%;
			max-width: 1000px;
		}
		&__photo{
			width: 420px;
			float: right;
			@include mq(tb){
				width: 280px;
			}
			@include mq(sp){
				margin-bottom: 20px;
				width: 100%;
				float: none;
			}
			&__link{
				display: block;
				&:hover{
					@include mq(pc){
						opacity: 0.7;
					}
				}
			}
		}
		&__text-wrapper{
			width: calc( 100% - 470px );
			float: left;
			@include mq(tb){
				width: calc( 100% - 310px );
			}
			@include mq(sp){
				width: 100%;
				float: none;
			}
			&__date{
				margin-bottom: 10px;
				font-size: 16px;
				line-height: 26px;
				color: $cl-black;
				@include mq(sp){
					margin-bottom: 7px;
					font-size: 14px;
					line-height: 22px;
				}
			}
			&__icon{
				margin-left: 10px;
				width: 100px;
				height: 26px;
				font-size: 13px;
				line-height: 27px;
				color: #5a4;
				font-family: $f-noto;
				font-weight: 400;
				text-align: center;
				background-color: #fff;
				display: inline-block;
				@include mq(sp){
					margin-left: 7px;
					width: 80px;
					height: 22px;
					font-size: 12px;
					line-height: 22px;
				}
			}
			&__copy{
				margin-bottom: 10px;
				font-size: 24px;
				line-height: 1.75;
				color: $cl-black;
				font-family: $f-mincho;
				font-weight: 400;
				text-align: justify;
				@include mq(tb){
					margin-bottom: 8px;
					font-size: 20px;
				}
				@include mq(sp){
					margin-bottom: 5px;
					font-size: 18px;
				}
			}
			&__text{
				font-size: 16px;
				line-height: 2.0;
				color: $cl-black;
				text-align: justify;
				@include mq(tb){
					font-size: 15px;
				}
				@include mq(sp){
					font-size: 14px;
					line-height: 1.9;
				}
				&__link{
					color: #5a4;
					text-decoration: underline;
					&:hover{
						@include mq(pc){
							text-decoration: none;
						}
					}
				}
			}
		}
	}
}