@charset "utf-8";

// mediaquery

//自由に追加してもらってOK
$breakpoints: (
    'w1800': 'screen and (max-width: 1800px)',
    'w1700': 'screen and (max-width: 1700px)',
    'w1600': 'screen and (max-width: 1600px)',
    'w1500': 'screen and (max-width: 1500px)',
    'w1400': 'screen and (max-width: 1400px)',
    'w1300': 'screen and (max-width: 1300px)',
    'w1200': 'screen and (max-width: 1200px)',
    'w1120': 'screen and (max-width: 1120px)',
    'w1100': 'screen and (max-width: 1100px)',
    'pc': 'screen and (min-width: 1025px)',/*自由です*/
    'tb': 'screen and (max-width: 1024px)',/*iPad iPad Pro(12.9inch)まで*/
    'tb02': 'screen and (max-width: 900px)',/*iPad iPad Pro(12.9inch)まで*/
    'sp': 'screen and (max-width: 599px)',/*SP*/
    'w320': 'screen and (max-width: 320px)'/*iPhoneSE*/
) !default;


@mixin mq($breakpoint) {
    @media #{map-get($breakpoints, $breakpoint)} {
        @content;
    }
}

/* ブレイクポイント参考

PC:インナーコンテンツサイズ以上
Laptop-HiDPI:1440
Laptop-MDPI :1280
iPad Pro(10.5inch):834
iPad Pro(12.9inch):1024
iPad:601
SP:600

*/

/* レスポンシブサイト非表示 */

/* PCのみ */
@media screen and (max-width:1025px) {
    .pc {
        display: none !important;
    }
}

/* 1400以上 */
@media screen and (max-width: 1400px) {
    .only1400 {
        display: none !important;
    }
}

/* 1200以上 */
@media screen and (max-width: 1200px) {
    .only1200 {
        display: none !important;
    }
}

/* タブレット・PC */
@media screen and (max-width: 599px) {
    .tbpc {
        display: none !important;
    }
}

/* タブレット・SP */
@media screen and (min-width:1025px) {
    .tbsp {
        display: none !important;
    }
}

/* タブレットのみ */
@media screen and (max-width: 599px) , (min-width: 1025px) {
    .tb {
        display: none !important;
    }
}

/* SPのみ */
@media screen and (min-width:600px) {
    .sp {
        display: none !important;
    }
}

@mixin clearfix() {
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}

@mixin box-sizing($type) {
	-moz-box-sizing: $type;
	-webkit-box-sizing: $type;
	-o-box-sizing: $type;
	-ms-box-sizing: $type;
	box-sizing: $type;
}

@mixin border-radius($type) {
	-moz-border-radius: $type;
	-webkit-border-radius: $type;
	-o-border-radius: $type;
	-ms-border-radius: $type;
	border-radius: $type;
}

//電話無効
@include mq(pc) {
    a[href^="tel:"] {
        pointer-events: none;
        cursor: auto;
    }
}

//フレックスボックス
@mixin display-flex {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

/* flex-wrap: wrap; */
@mixin flex-wrap {
	-ms-flex-wrap: wrap;
	-webkit-flex-wrap : wrap;
  	flex-wrap: wrap;
}

/*左右中央*/
@mixin justify-content-center{
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

/*上下中央*/
@mixin align-items-center{
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}
