
@charset "utf-8";


.table01{
    width: 100%;
    border: 1px solid #dde3e7;
    border-collapse: collapse;
    th{
        width: 24%;
        font-size: 16px;
        line-height: 1.6;
        color: #fff;
        text-align: center;
        vertical-align: middle;
        background-color: $cl-black;
        border: 1px solid #dde3e7;
        border-right: none;
        @include mq(tb){
            font-size: 14px;
        }
        @include mq(sp){
            width: 28%;
            font-size: 12px;
			line-height: 1.4;
        }
    }
    td{
        width: 76%;
        vertical-align: middle;
        border: 1px solid #dde3e7;
        border-left: none;
        @include mq(sp){
            width: 72%;
        }
        .td-text{
            padding: 30px;
            font-size: 16px;
            line-height: 1.6;
            color: $cl-black;
            text-align: left;
            position: relative;
            &::before{
                content: '';
                top: calc( 50% - 5px );
                left: 0;
                border-width: 5px 0 5px 6px;
                border-style: solid;
                border-color: transparent transparent transparent $cl-black;
                position: absolute;
            }
            @include mq(tb){
				padding: 20px 25px;
				font-size: 15px;
			}
            @include mq(sp){
                padding: 15px;
                font-size: 13px;
				&::before{
					content: '';
					top: calc( 50% - 3px );
					border-width: 3px 0 3px 4px;
				}
            }
        }
		.td-link{
			padding-left: 1em;
			color: $cl-black;
			position: relative;
			display: block;
			&::before{
				content: "";
				top: 8px;
				left: 0;
				border-width: 4px 0 4px 7px;
				border-style: solid;
				border-color: transparent transparent transparent $cl-gray;
				position: absolute;
				@include mq(sp){
					top: 5px;
				}
			}
			&:hover{
				@include mq(pc){
					color: $cl-gray;
				}
			}
		}
    }
}