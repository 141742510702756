
@charset "utf-8";

.footer-btn-wrapper{
    margin: 9.5vw 0 1px;
    width: auto;
    @include mq(sp){
        margin: 40px 0 1px;
    }
    &__item{
        margin-left: 1px;
        width: calc( ( 99.9% - 2px ) / 3 );
        float: left;
        &:first-child{
            margin-left: 0;
        }
        @include mq(sp){
            margin-top: 1px;
            margin-left: 0;
            width: auto;
            float: none;
        }
        &__link{
            padding-top: 4.2vw;
            width: 100%;
            height: 25vw;
            text-align: center;
            display: block;
            position: relative;
            overflow: hidden;
            box-sizing: border-box;
            @include mq(w1400){
                height: 26vw;
            }
            @include mq(w1200){
                padding-top: 3.7vw;
            }
            @include mq(tb){
                padding-top: 4.5vw;
                height: 32vw;
            }
            @include mq(sp){
                padding: 12.5vw 20px;
                height: auto;
                text-align: center;
            }
            &:hover{
                .footer-btn-wrapper__item__link__img{
                    transform: scale(1.1);
                }
                .footer-btn-wrapper__item__link__text{
                    &::before{
                        background-color: $cl-gray;
                    }
                }
                @include mq(tb){
                    .footer-btn-wrapper__item__link__img{
                        transform: scale(1.0);
                    }
                    .footer-btn-wrapper__item__link__text{
                        &::before{
                            background-color: $cl-black;
                        }
                    }
                }
            }
            &__img{
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: 50% 50%;
                position: absolute;
                transition: 0.5s;
                &--chose{
                    background-image: url(../img/common/chose.jpg);
                }
                &--learn{
                    background-image: url(../img/common/learn.jpg);
                    @include mq(sp){
                        background-position: 50% 75%
                    }
                }
                &--know{
                    background-image: url(../img/common/know.jpg);
                    @include mq(sp){
                        background-position: 50% 88%
                    }
                }
            }
            &__text{
                padding-top: 2.1vw;
                font-size: 1.45vw;
                line-height: 1.01em;
                color: #fff;
                font-family: $f-mincho;
                font-weight: 400;
                letter-spacing: 0.08em;
                -webkit-writing-mode: vertical-rl;
                -ms-writing-mode: tb-rl;
                -o-writing-mode: vertical-rl;
                writing-mode: vertical-rl;
                display: inline-block;
                position: relative;
                &::before{
                    content: '';
                    top: 0;
                    left: calc( 50% - 0.9vw );
                    width: 1.8vw;
                    height: 1.8vw;
                    background-color: $cl-black;
                    background-image: url(../img/common/arrow_white.svg);
                    background-size: 26.6% auto;
                    background-position: 55% 50%;
                    background-repeat: no-repeat;
                    @include border-radius( 50% );
                    position: absolute;
                    transition: 0.5s;
                }
                @include mq(w1400){
                    padding-top: 2.3vw;
                    font-size: 1.55vw;
                    &::before{
                        left: calc( 50% - 1vw );
                        width: 2vw;
                        height: 2vw;
                    }
                }
                @include mq(w1200){
                    padding-top: 2.45vw;
                    font-size: 1.7vw;
                    &::before{
                        left: calc( 50% - 1.1vw );
                        width: 2.2vw;
                        height: 2.2vw;
                    }
                }
                @include mq(tb){
                    padding-top: 3.3vw;
                    font-size: 2.0vw;
                    &::before{
                        left: calc( 50% - 1.5vw );
                        width: 3.0vw;
                        height: 3.0vw;
                    }
                }
                @include mq(sp){
                    padding-top: 0;
                    font-size: 16px;
                    text-align: left;
                    -webkit-writing-mode: horizontal-tb;
                    -ms-writing-mode: lr-tb;
                    -o-writing-mode: horizontal-tb;
                    writing-mode: horizontal-tb;
                    display: block;
                    &::before{
                        top: -2px;
                        left: auto;
                        right: 0;
                        width: 20px;
                        height: 20px;
                        background-color: #fff;
                        background-image: url(../img/common/arrow_black.svg);
                    }
                }
            }
        }
    }
}

.footer-wrapper{
    padding: 55px 40px 65px 30px;
    background-color: $cl-black;
    @include mq(w1300){
        padding: 45px 40px 40px 30px;
    }
    @include mq(tb){
        padding:35px 30px 30px;
    }
    &__address{
        float: left;
        @include mq(w1400){
            margin-bottom: 15px;
            float: none;
        }
        @include mq(tb){
            margin-bottom: 10px;
        }
        @include mq(sp){
            margin-bottom: 0;
        }
        &__logo{
            margin-bottom: 10px;
            width: 220px;
            @include mq(tb){
                margin-bottom: 6px;
                width: 190px;
            }
            @include mq(sp){
                margin: 0 auto 10px;
                width: 150px;
            }
        }
        &__text{
            padding-left: 48px;
            font-size: 14px;
            line-height: 1.6;
            color: #fff;
            @include mq(tb){
                padding-left: 40px;
            }
            @include mq(sp){
                padding-left: 0;
                font-size: 13px;
                text-align: center;
            }
            &__tel-link{
                margin: 0 1em;
                color: #fff;
                cursor: text;
                display: inline-block;
                @include mq(sp){
                    margin: 0 1em 0 0;
                }
            }
        }
    }
    &__nav{
        font-size: 15px;
        line-height: 1.01;
        color: #fff;
        font-family: $f-mincho;
        text-align: right;
        float: right;
        @include mq(w1400){
            float: none;
        }
        @include mq(tb){
            padding-left: 40px;
            font-size: 13px;
            text-align: left;
        }
        @include mq(sp){
            display: none;
        }
        &__item{
            display: inline-block;
            &::before{
                content: '｜';
            }
            &:first-child{
                &::before{
                    display: none;
                }
            }
            &__link{
                color: #fff;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
}

.copyright{
	padding-bottom: 120px;
    padding-left: 0.06em;
    font-size: 14px;
    line-height: 65px;
    color: $cl-black;
    letter-spacing: 0.06em;
    text-align: center;
    @include mq(tb){
		padding-bottom: 110px;
        line-height: 50px;
    }
    @include mq(sp){
		padding-bottom: 80px;
        margin-bottom: 0;
        line-height: 40px;
        font-size: 12px;
    }
}

.footer-online-btn{
	left: 0;
	bottom: 0;
	padding: 20px;
	width: 100%;
	background-color: rgba($cl-black,0.7);
	position: fixed;
	z-index: 9;
	@include mq(sp){
		padding: 10px;
	}
	&__link{
		margin: 0 auto;
		padding-left: 0.1em;
		width: 440px;
		max-width: 100%;
		height: 80px;
		font-size: 17px;
		line-height: 78px;
		color: #fff;
		font-family: $f-mincho;
		font-weight: 400;
		letter-spacing: 0.1em;
		text-align: center;
		background-color: $cl-gray;
		background-image: url(../img/common/icon_cart_white.svg);
		background-size: 19px auto;
		background-repeat: no-repeat;
		background-position: right 30px top 50%;
		display: block;
		@include mq(tb){
			height: 70px;
			line-height: 68px;
		}
		@include mq(sp){
			height: 60px;
			font-size: 15px;
			line-height: 58px;
			background-position: right 20px top 50%;
		}
		&:hover{
			@include mq(pc) {
				color: $cl-black;
				background-color: #fff;
				background-image: url(../img/common/icon_cart.svg);
			}
		}
	}
}