
@charset "utf-8";

.tea-lead{
    padding: 0 40px 80px;
    @include mq(tb){
        padding: 0 40px 30px;
    }
    @include mq(sp){
        padding: 0 20px 30px;
    }
	&__copy{
		font-size: 22px;
		line-height: 2.0;
		color: #262;
		font-family: $f-mincho;
		font-weight: 400;
		text-align: center;
		@include mq(tb){
			font-size: 18px;
		}
		@include mq(sp){
			font-size: 14px;
			line-height: 1.8;
			text-align: justify;
		}
	}
}

.tea-wrapper{
	&__photo {
        top: 90px;
        width: 50%;
        height: calc( 100vh - 90px);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 50%;
        position: -webkit-sticky;
        position: sticky;
		float: left;
		z-index: 2;
		@include mq(w1200){
			top: auto;
			width: 100%;
			height: auto;
			float: none;
			position: -webkit-relative;
			position: relative;
			&::before{
				content: '';
				padding-top: 45%;
				display: block;
			}
		}
		&--01{
			background-image: url(../img/tea/photo01.jpg);
		}
		&--02{
			background-image: url(../img/tea/photo02.jpg);
			float: right;
			@include mq(w1200){
				float: none;
			}
		}
		&__title{
			right: -30px;
			bottom: 100px;
			padding: 24px 30px;
			background-color: $cl-black;
			position: absolute;
			@include mq(w1200){
				right: 0;
				bottom: -50px;
			}
			@include mq(tb){
				padding: 18px 25px;
				bottom: -38px;
			}
			@include mq(sp){
				padding: 14px 15px 0 15px;
				width: 100%;
				height: 62px;
				bottom: -62px;
			}
			&--02{
				right: auto;
				left: -30px;
				@include mq(w1200){
					right: 0;
					left: auto;
				}
			}
			&__number{
				margin-bottom: 8px;
				font-size: 16px;
				line-height: 1.01;
				color: #fff;
				font-family: $f-noto;
				font-weight: 700;
				@include mq(tb){
					margin-bottom: 5px;
					font-size: 14px;
				}
				@include mq(sp){
					margin-bottom: 3px;
					font-size: 11px;
				}
			}
			&__name{
				font-size: 28px;
				line-height: 1.01;
				color: #fff;
				font-family: $f-noto;
				font-weight: 500;
				letter-spacing: 0.05em;
				@include mq(tb){
					font-size: 22px;
				}
				@include mq(sp){
					font-size: 18px;
					letter-spacing: 0;
				}
				&__en{
					margin-left: 10px;
					font-size: 0.5em;
					color: #7c5;
					font-family: $f-lato;
					font-weight: 700;
					vertical-align: 28%;
					letter-spacing: 0.08em;
					display: inline-block;
					@include mq(sp){
						margin-left: 5px;
						font-size: 0.6em;
						letter-spacing: 0;
					}
				}
			}
		}
	}
	&__text-wrapper{
		padding: 90px 60px 100px;
		width: 50%;
		background-color: $cl-bg-gray;
		float: right;
		@include mq(w1200){
			padding: 90px 60px 100px;
			width: 100%;
			float: none;
		}
		@include mq(tb){
			padding: 60px;
		}
		@include mq(sp){
			padding: 85px 20px 30px;
		}
		&--left{
			float: left;
			@include mq(w1200){
				float: none;
			}
		}
		&__inner{
			margin: 0 auto;
			width: 100%;
			max-width: 600px;
			@include mq(w1200){
				max-width: 800px;
			}
			&__copy{
				margin-bottom: 25px;
				font-size: 29px;
				line-height: 1.8;
				color: $cl-black;
				font-family: $f-mincho;
				font-weight: 400;
				@include mq(w1200){
					margin-bottom: 15px;
					font-size: 26px;
					text-align: center;
				}
				@include mq(sp){
					margin-bottom: 10px;
					font-size: 4.8vw;
					line-height: 1.6;
					text-align: justify;
				}
			}
			&__text{
				margin-bottom: 40px;
				font-size: 16px;
				line-height: 2.25;
				color: $cl-black;
				text-align: justify;
				@include mq(w1200){
					margin-bottom: 30px;
					line-height: 2;
				}
				@include mq(sp){
					margin-bottom: 15px;
					font-size: 14px;
					line-height: 1.9;
				}
			}
			&__item{
				margin-top: 20px;
				padding: 20px 30px 20px 20px;
				background-color: #fff;
				@include mq(sp){
					margin-top: 10px;
					padding: 20px;
				}
				&:first-child{
					margin-top: 0;
				}
				&__photo{
					width: 170px;
					float: left;
					@include mq(w1500){
						width: 140px;
					}
					@include mq(w1200){
						width: 170px;
					}
					@include mq(sp){
						margin: 0 auto 15px;
						width: 100%;
						float: none;
						position: relative;
						overflow: hidden;
						&::before{
							content: '';
							padding-top: 40%;
							display: block;
						}
						img{
							top: -30%;
							left: 0;
							width: 100%;
							position: absolute;
						}
					}
				}
				&__text-wrapper{
					padding-top: 10px;
					width: calc( 100% - 190px );
					float: right;
					@include mq(w1500){
						padding-top: 3px;
						width: calc( 100% - 155px );
					}
					@include mq(w1200){
						padding-top: 10px;
						width: calc( 100% - 190px );
					}
					@include mq(tb){
						padding-top: 5px;
					}
					@include mq(sp){
						padding-top: 0;
						width: 100%;
						float: none;
					}
					&__title {
						margin-bottom: 10px;
						font-size: 22px;
						line-height: 1.0;
						color: #262;
						font-family: $f-mincho;
						font-weight: 400;
						letter-spacing: 0.08em;
						@include mq(w1500) {
							font-size: 20px;
						}
						@include mq(w1200) {
							font-size: 22px;
						}
						@include mq(sp) {
							margin-bottom: 10px;
							font-size: 20px;
							text-align: center;
						}
						&__sub{
							font-size: 14px;
						}
					}
					&__text{
						font-size: 14px;
						line-height: 1.8;
						color: $cl-black;
						text-align: justify;
						@include mq(w1500) {
							font-size: 13px;
						}
						@include mq(w1200) {
							font-size: 16px;
						}
						@include mq(tb) {
							font-size: 15px;
						}
						@include mq(sp) {
							font-size: 14px;
						}
					}
				}
			}
		}
	}
}

.tea-type{
	padding: 100px 60px 0;
    @include mq(tb){
        padding: 40px 60px 0;
    }
    @include mq(sp){
        padding: 30px 20px 0;
    }
	&__copy{
		margin-bottom: 20px;
		padding-left: 0.1em;
		font-size: 20px;
		line-height: 2.2;
		color: $cl-black;
		font-family: $f-mincho;
		font-weight: 400;
		text-align: center;
		letter-spacing: 0.1em;
		@include mq(tb){
			margin-bottom: 10px;
			font-size: 16px;
			line-height: 2;
		}
		@include mq(sp){
			padding-left: 0;
			font-size: 14px;
			line-height: 1.9;
			text-align: justify;
		}
	}
	&__inner{
		margin: -50px auto 0;
		width: 100%;
		max-width: 1440px;
		text-align: center;
		@include mq(sp){
			margin-top: 0;
		}
		&__item{
			margin-top: 60px;
			margin-left: 60px;
			width: calc( ( 99.9% - 120px ) / 3 );
			display: inline-block;
			vertical-align: top;
			@include mq(w1400){
				margin-left: 40px;
				width: calc( ( 99.9% - 80px ) / 3 );
			}
			&:nth-child(3n-2){
				margin-left: 0;
			}
			@include mq(tb){
				margin-left: 40px;
				width: calc( 50% - 20px );
				&:nth-child(3n-2){
					margin-left: 40px;
				}
				&:nth-child(2n-1){
					margin-left: 0;
				}
			}
			@include mq(sp){
				margin-top: 30px;
				margin-left: 0;
				width: 100%;
				display: block;
				&:nth-child(3n-2){
					margin-left: 0;
				}
				&:nth-child(2n-1){
					margin-left: 0;
				}
				&:first-child{
					margin-top: 0;
				}
			}
			&__photos{
				&__item{
					width: calc( 50% - 2px );
					float: right;
					&:first-child{
						float: left;
					}
					@include mq(sp){
						width: calc( 50% - 1px );
					}
				}
			}
			&__title{
				margin-top: 18px;
				margin-bottom: 12px;
				font-size: 22px;
				line-height: 1;
				color: $cl-black;
				font-family: $f-mincho;
				font-weight: 400;
				text-align: left;
                @include mq(sp){
                    margin-top: 13px;
					margin-bottom: 10px;
					font-size: 20px;
                }
				&__rubi{
					font-size: 0.637em;
					vertical-align: 20%;
				}
			}
			&__text{
				font-size: 15px;
				line-height: 1.85;
				color: $cl-black;
				text-align: justify;
				@include mq(w1300){
					font-size: 14px;
				}
			}
		}
	}
}