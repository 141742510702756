
@charset "utf-8";

.menu-inner{
	padding: 100px;
	height: 100%;
	display: none;
	transition: 0.5s;
	background-image: url(../img/common/menu_photo01.png),url(../img/common/menu_photo02.png);
	background-size: 50% auto , 50% auto;
	background-repeat: no-repeat , no-repeat;
	background-position: 0 100% , 100% 100%;
	@include mq(tb){
		padding: 40px 40px 100px;
	}
	@include mq(sp){
		padding: 20px 20px 100px;
		height: auto;
		background-image: none;
	}
	&__search{
		margin: 0 auto 60px;
		width: 100%;
		max-width: 600px;
		@include mq(tb){
			margin-bottom: 40px;
		}
		@include mq(sp){
			margin-bottom: 20px;
		}
		&__inner{
			width: calc( 100% - 60px );
			float: left;
            @include mq(sp){
                width: calc( 100% - 50px );
            }
			input{
				padding-left: 15px;
				width: 100%;
				height: 60px;
				font-size: 20px;
				line-height: 60px;
				color: $cl-black;
				background-color: #fff;
				@include border-radius(0);
				border: none;
				@include mq(sp){
					padding-left: 10px;
					height: 50px;
					font-size: 16px;
					line-height: 50px;
				}
			}
			input[type="text"]:focus {
			  border: none;
			  outline: 0;
			}
		}
		&__btn{
			width: 60px;
			height: 60px;
			background-color: #fff;
			background-image: url(../img/common/icon_search.svg);
			background-repeat: no-repeat;
			background-size: 50% auto;
			background-position: 50% 50%;
			display: block;
			float: right;
            @include mq(sp){
				width: 50px;
                height: 50px;
            }
			&:hover{
				@include mq(pc){
					background-image: url(../img/common/icon_search_black.svg);
				}
			}
		}
	}
	&__sitemap{
		margin: 0 auto;
		width: 100%;
		max-width: 1560px;
		@include mq(w1400){
			max-width: 1100px;
		}
		&__company{
			width: 50%;
			float: left;
			@include mq(w1400){
				width: calc( 50% - 20px );
			}
			@include mq(sp){
				margin-bottom: 30px;
				width: auto;
				float: none;
			}
		}
		&__shopping{
			width: 50%;
			float: right;
			@include mq(w1400){
				width: calc( 50% - 20px );
			}
			@include mq(sp){
				margin-bottom: 30px;
				width: auto;
				float: none;
			}
		}
		&__title{
			width: 250px;
			height: 250px;
			text-align: center;
			border: 1px solid #999;
			float: left;
			position: relative;
			@include mq(w1500){
				width: 210px;
				height: 210px;
			}
			@include mq(w1400){
				margin-bottom: 30px;
				width: 100%;
				height: auto;
				float: none;
			}
			@include mq(tb02){
				margin-bottom: 20px;
			}
			@include mq(sp){
				margin-bottom: 0;
			}
			&__text{
				left: 50%;
				height: 100%;
				font-size: 21px;
				line-height: 1.01;
				color: #fff;
				font-family: $f-mincho;
				font-weight: 400;
				letter-spacing: 0.1em;
				-ms-writing-mode: tb-rl;
				writing-mode: vertical-rl;
				text-align: center;
				transform: translateX( -50% );
				display: block;
				position: absolute;
				@include mq(w1500){
					font-size: 18px;
				}
				@include mq(w1400){
					left: auto;
					padding-left: 0.1em;
					height: auto;
					font-size: 21px;
					line-height: 78px;
					-webkit-writing-mode: horizontal-tb;
					-ms-writing-mode: lr-tb;
					-o-writing-mode: horizontal-tb;
					writing-mode: horizontal-tb;
					text-align: center;
					transform: translateX( 0 );
					display: block;
					position: relative;
				}
				@include mq(tb02){
					font-size: 18px;
					line-height: 75px;
				}
				@include mq(sp){
					font-size: 17px;
					line-height: 50px;
				}
			}
		}
		&__links{
			padding-top: 10px;
			padding-left: 40px;
			width: calc( 100% - 250px );
			max-width: 430px;
			float: left;
			box-sizing: border-box;
			@include mq(w1500){
				padding-left: 25px;
				width: calc( 100% - 210px );
			}
			@include mq(w1400){
				padding: 0;
				width: auto;
				max-width: 600px;
				float: none;
			}
			ul{
				overflow: hidden;
			}
			&__item{
				margin-bottom: 17px;
				width: 50%;
				float: left;
				&:first-child{
					width: 100%;
					float: none;
				}
				@include mq(tb02){
					width: 100%;
					float: none;
				}
				@include mq(sp){
					margin: 0;
					border-bottom: 1px dotted #999;
				}
				&__link{
					padding-left: 1em;
					font-size: 17px;
					line-height: 1.01;
					color: #fff;
					font-family: $f-mincho;
					font-weight: 400;
					background-image: url(../img/common/arrow_white.svg);
					background-repeat: no-repeat;
					background-position: 0 4px;
					background-size: 9px auto;
					display: block;
					@include mq(sp){
						padding: 18px 20px;
						font-size: 15px;
						background-position: 5px 22px;
						display: block;
					}
					&:hover{
						@include mq(pc){
							opacity: 0.7;
							background-position: 4px 4px;
						}
					}
					&--open{
						position: relative;
						&::before{
							content: '';
							top: 8px;
							right: 0;
							width: 19px;
							height: 1px;
							background-color: #fff;
							position: absolute;
						}
						&::after{
							content: '';
							top: 8px;
							right: 0;
							width: 19px;
							height: 1px;
							background-color: #fff;
							position: absolute;
							transition: 0.5s;
						}
						@include mq(sp){
							&::before{
								top: 24px;
								right: 10px;
							}
							&::after{
								top: 24px;
								right: 10px;
							}
						}
						&.close{
							&::after{
								transform: rotate( 90deg );
							}
						}
					}
				}
			}
			.sub-wrapper{
				padding-top: 10px;
				@include mq(sp){
					margin-top: -5px;
					padding: 0 0 20px 20px;
				}
			}
			&__sub{
				margin-top: -5px;
				@include mq(sp){
					margin-top: 10px;
				}
				&__item{
					margin-top: 5px;
                    width: 50%;
                    float: left;
                    &:nth-child(even){
                        float: right;
					}
					@include mq(sp){
						width: auto;
						float: none;
						&:nth-child(even){
							float: none;
						}
					}
					&__link{
						padding-left: 18px;
						font-size: 14px;
						line-height: 1.01;
						color: #fff;
						position: relative;
						&::before{
							content: '';
							top: 7px;
							left: 0;
							width: 10px;
							height: 1px;
							background-color: #fff;
							position: absolute;
							transition: 0.5s;
						}
						&:hover{
							@include mq(pc){
								opacity: 0.7;
								&::before{
									left: 3px;
								}
							}
						}
					}
				}
			}
		}
	}
}

input::placeholder{
    color: #aaa;
}

/* IE */
input:-ms-input-placeholder {
  color:  #aaa;
}

/* Edge */
input::-ms-input-placeholder {
  color:  #aaa;
}
