
@charset "utf-8";

.about-lead{
    padding: 0 40px 80px;
    @include mq(tb){
        padding: 0 40px 40px;
    }
    @include mq(sp){
        padding: 0 20px 30px;
    }
	&__copy{
		font-size: 22px;
		line-height: 2.2;
		color: #262;
		font-family: $f-mincho;
		font-weight: 400;
		text-align: center;
		@include mq(w1300){
			font-size: 19px;
		}
		@include mq(w1100){
			font-size: 17px;
		}
		@include mq(tb){
			font-size: 16px;
			text-align: justify;
		}
		@include mq(sp){
			font-size: 14px;
			line-height: 1.8;
		}
	}
}

.about-wrapper{
	&__photo {
        top: 90px;
        width: 50%;
        height: calc( 100vh - 90px);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 50%;
        position: -webkit-sticky;
        position: sticky;
		float: left;
		z-index: 2;
		@include mq(w1200){
			top: auto;
			width: 100%;
			height: auto;
			float: none;
			position: -webkit-relative;
			position: relative;
			&::before{
				content: '';
				padding-top: 45%;
				display: block;
			}
		}
		&--01{
			background-image: url(../img/about/photo01.jpg);
		}
		&--02{
			background-image: url(../img/about/photo02.jpg);
			float: right;
			@include mq(w1200){
				float: none;
			}
		}
		&--03{
			background-image: url(../img/about/photo03.jpg);
			@include mq(w1200){
				background-position: 50% 25%;
			}
		}
		&__title{
			right: -30px;
			bottom: 100px;
			padding: 24px 30px;
			background-color: $cl-black;
			position: absolute;
			@include mq(w1200){
				right: 0;
				bottom: -50px;
			}
			@include mq(tb){
				padding: 18px 25px;
				bottom: -38px;
			}
			@include mq(sp){
				padding: 14px 15px 0 15px;
				width: 100%;
				height: 62px;
				bottom: -62px;
			}
			&--02{
				right: auto;
				left: -30px;
				@include mq(w1200){
					right: 0;
					left: auto;
				}
			}
			&__number{
				margin-bottom: 8px;
				font-size: 16px;
				line-height: 1.01;
				color: #fff;
				font-family: $f-noto;
				font-weight: 700;
				@include mq(tb){
					margin-bottom: 5px;
					font-size: 14px;
				}
				@include mq(sp){
					margin-bottom: 3px;
					font-size: 11px;
				}
			}
			&__name{
				font-size: 28px;
				line-height: 1.01;
				color: #fff;
				font-family: $f-noto;
				font-weight: 500;
				letter-spacing: 0.05em;
				@include mq(tb){
					font-size: 22px;
				}
				@include mq(sp){
					font-size: 18px;
					letter-spacing: 0;
				}
				&__en{
					margin-left: 10px;
					font-size: 0.5em;
					color: #7c5;
					font-family: $f-lato;
					font-weight: 700;
					vertical-align: 28%;
					letter-spacing: 0.08em;
					display: inline-block;
					@include mq(sp){
						margin-left: 5px;
						font-size: 0.6em;
						letter-spacing: 0;
					}
				}
			}
		}
	}
	&__text-wrapper{
		padding: 90px 60px 100px;
		width: 50%;
		background-color: $cl-bg-gray;
		float: right;
		@include mq(w1200){
			padding: 90px 60px 100px;
			width: 100%;
			float: none;
		}
		@include mq(tb){
			padding: 60px;
		}
		@include mq(sp){
			padding: 85px 20px 30px;
		}
		&--left{
			float: left;
			@include mq(w1200){
				float: none;
			}
		}
		&__inner{
			margin: 0 auto;
			width: 100%;
			max-width: 600px;
			@include mq(w1200){
				max-width: 800px;
			}
			&__copy{
				margin-bottom: 25px;
				font-size: 29px;
				line-height: 1.4;
				color: $cl-black;
				font-family: $f-mincho;
				font-weight: 400;
				@include mq(w1200){
					margin-bottom: 15px;
					font-size: 26px;
					text-align: center;
				}
				@include mq(sp){
					margin-bottom: 10px;
					font-size: 4.8vw;
				}
			}
			&__text{
				margin-bottom: 40px;
				font-size: 16px;
				line-height: 2.25;
				color: $cl-black;
				text-align: justify;
				@include mq(w1200){
					margin-bottom: 30px;
					line-height: 2;
				}
				@include mq(sp){
					margin-bottom: 15px;
					font-size: 14px;
					line-height: 1.9;
				}
			}
			&__item{
				margin-top: 20px;
				padding: 30px;
				background-color: #fff;
				@include mq(sp){
					margin-top: 10px;
					padding: 20px;
				}
				&:first-child{
					margin-top: 0;
				}
				&__title{
					margin-bottom: 10px;
					padding-left: 25px;
					font-size: 17px;
					line-height: 1.01;
					color: $cl-black;
					font-family: $f-mincho;
					font-weight: 400;
					position: relative;
					&::before{
						content: '';
						top: 0;
						left: 0;
						width: 18px;
						height: 18px;
						border: 5px solid #7c5;
						border-radius: 50%;
						position: absolute;
					}
					@include mq(sp){
						margin-bottom: 7px;
						padding-left: 22px;
						font-size: 16px;
						&::before{
							width: 16px;
							height: 16px;
							border: 4px solid #7c5;
						}
					}
				}
				&__text{
					font-size: 14px;
					line-height: 1.8;
					color: $cl-black;
					text-align: justify;
					&__img{
						margin-bottom: 10px;
						margin-left: 20px;
						width: 130px;
						float: right;
						@include mq(sp){
							padding-top: 5px;
							margin-bottom: 5px;
							margin-left: 15px;
							width: 90px;
						}
						&--02{
							width: 80px;
							@include mq(sp){
								width: 55px;
							}
						}
					}
				}
			}
		}
	}
}

.about-history{
	padding: 120px 40px;
	@include mq(w1200){
		padding: 100px 40px;
	}
	@include mq(tb){
		padding: 50px 40px;
	}
	@include mq(sp){
		padding: 30px 20px;
	}
	&__inner{
		margin: 0 auto;
		width: 800px;
		height: 341px;
		@include mq(tb){
			width: 670px;
			height: 330px;
		}
        @include mq(sp){
			width: auto;
			height: auto;
        }
		&__copy{
			font-size: 26px;
			line-height: 2.3;
			color: $cl-black;
			font-family: $f-mincho;
			font-weight: 400;
			text-align: left;
			letter-spacing: 0.09em;
			-ms-writing-mode: tb-rl;
        	writing-mode: vertical-rl;
			float: right;
			@include mq(tb){
				font-size: 25px;
				line-height: 1.9;
			}
			@include mq(sp){
				margin-bottom: 10px;
				font-size: 5.3vw;
				line-height: 1.9;
				-webkit-writing-mode: horizontal-tb;
				-ms-writing-mode: lr-tb;
				-o-writing-mode: horizontal-tb;
				writing-mode: horizontal-tb;
				float: none;
			}
		}
		&__text{
			font-size: 16px;
			line-height: 2.75;
			color: $cl-black;
			font-family: $f-mincho;
			font-weight: 400;
			text-align: justify;
			-ms-writing-mode: tb-rl;
        	writing-mode: vertical-rl;
			float: left;
			@include mq(tb){
				font-size: 15px;
				line-height: 2.5;
			}
			@include mq(sp){
				line-height: 1.9;
				-webkit-writing-mode: horizontal-tb;
				-ms-writing-mode: lr-tb;
				-o-writing-mode: horizontal-tb;
				writing-mode: horizontal-tb;
			}
		}
	}
}

.about-history-photos{
	margin-bottom: calc( -9.5vw + 1px );
	@include mq(sp){
		margin-bottom: -39px;
	}
	&__item{
		width: calc( 99.9% / 6 );
		float: left;
		@include mq(tb){
			width: calc( 99.9% / 3 );
		}
	}
}

.about-jusho{
	padding: 100px 60px 0;
	@include mq(tb){
		padding: 40px 40px 0;
	}
	@include mq(sp){
		padding: 30px 20px 0;
	}
	&__inner{
		margin: 0 auto 0;
		padding: 50px;
		width: 100%;
		max-width: 1000px;
		border: 2px solid $cl-black;
		position: relative;
		@include mq(tb){
			padding: 40px;
		}
		@include mq(sp){
			padding: 25px;
		}
		&::before{
			content: '';
			top: 3px;
			left: 3px;
			width: calc( 100% - 6px );
			height: calc( 100% - 6px );
			border: 1px solid $cl-black;
			position: absolute;
			@include mq(sp){
				top: 2px;
				left: 2px;
				width: calc( 100% - 4px );
				height: calc( 100% - 4px );
			}
		}
		&__copy{
			margin-bottom: 25px;
			font-size: 26px;
			line-height: 1.01;
			color: $cl-black;
			font-family: $f-mincho;
			font-weight: 400;
			letter-spacing: 0.06em;
            @include mq(tb){
				margin-bottom: 20px;
				font-size: 24px;
            }
            @include mq(sp){
				margin-bottom: 10px;
				font-size: 5.3vw;
				line-height: 1.6;
            }
		}
		&__text{
			font-size: 16px;
			line-height: 2.0;
			color: $cl-black;
			text-align: justify;
            @include mq(tb){
				font-size: 15px;
            }
            @include mq(sp){
				font-size: 14px;
				line-height: 1.9;
            }
			&__img{
				padding-top: 5px;
				margin-left: 40px;
				width: 277px;
				float: right;
				display: block;
				@include mq(tb){
					margin-left: 20px;
					margin-bottom: 10px;
					width: 200px;
				}
				@include mq(sp){
					margin-left: 15px;
					margin-bottom: 5px;
					width: 37vw;
				}
			}
		}
	}
}