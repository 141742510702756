
@charset "utf-8";


.btn-arrow {
    margin: 60px auto 0;
    padding-left: 0.1em;
    width: 440px;
    max-width: 100%;
    height: 80px;
    font-size: 17px;
    line-height: 78px;
    color: #fff;
    font-family: $f-mincho;
    font-weight: 400;
    letter-spacing: 0.1em;
    text-align: center;
    background-color: $cl-black;
    background-image: url(../img/common/arrow_white.svg);
    background-size: 11px auto;
    background-repeat: no-repeat;
    background-position: center right 30px;
    display: block;
    @include mq(w1500){
        margin-top: 4vw;
    }
    @include mq(tb){
        margin-top: 40px;
    }
    @include mq(sp){
        margin-top: 20px;
        height: 60px;
        font-size: 15px;
        line-height: 58px;
        background-position: center right 20px;
    }
    &:hover{
        @include mq(pc) {
            background-color: $cl-gray;
            background-position: center right 20px;
        }
    }
	&--s{
		margin: 25px auto 0;
		width: 320px;
		max-width: 100%;
		height: 60px;
		font-size: 16px;
		line-height: 58px;
		background-position: center right 20px;
		@include mq(tb){
			margin-top: 20px;
		}
		@include mq(sp){
			margin-top: 15px;
			height: 50px;
			width: 280px;
			font-size: 15px;
			line-height: 48px;
			background-size: 9px auto;
		}
		&:hover{
			@include mq(pc) {
				background-color: $cl-gray;
				background-position: center right 13px;
			}
		}
	}
	&--tal{
		margin: 25px 0 0;
		@include mq(sp){
			margin: 15px auto 0;
		}
	}
}

.btn-icon{
    margin: 60px auto 0;
    padding-left: 0.1em;
    width: 440px;
    max-width: 100%;
    height: 80px;
    font-size: 17px;
    line-height: 78px;
    color: $cl-black;
    font-family: $f-mincho;
    font-weight: 400;
    letter-spacing: 0.1em;
    text-align: center;
    background-color: #fff;
    background-image: url(../img/common/icon_cart.svg);
    background-size: 19px auto;
    background-repeat: no-repeat;
    background-position: right 30px top 50%;
    display: block;
    @include mq(w1500){
        margin-top: 4vw;
    }
    @include mq(tb){
        margin-top: 30px;
    }
    @include mq(sp){
        margin-top: 15px;
        height: 60px;
        font-size: 15px;
        line-height: 58px;
        background-position: right 20px top 50%;
    }
    &:hover{
        @include mq(pc) {
            color: #fff;
            background-color: $cl-gray;
            background-image: url(../img/common/icon_cart_white.svg);
        }
    }
    &--black{
        color: #fff;
        background-color: $cl-black;
        background-image: url(../img/common/icon_list_white.svg);
        &:hover{
            @include mq(tb){
                color: #fff;
                background-color: $cl-black;
            }
        }
        &.insta {
            background-image: url(../img/common/icon_insta.svg);
            background-size: 18px auto;
        }
        &.contact {
            background-image: url(../img/common/icon_mail.svg);
            background-size: 25px auto;
			background-position: left 25px top 50%;
			@include mq(sp){
				background-position: left 20px top 50%;
			}
        }
    }
	&--mt0{
		margin-top: 0;
	}
}
