@charset "utf-8";


// プリントCSS

@media print {
    .sp {
        display: none !important;
    }
    body {
        width: 1140px; //A4縦に入るくらいの幅に調整（1080px～1200pxくらい？※サイト幅によって値を印刷プレビューしながら調整）
        -webkit-print-color-adjust: exact; //背景画像を印刷させる指定（Chromeのみ対応）
    }
    /*　------- ↓その他参考例　---------
    .header {
        position: relative !important; //ヘッダー固定fixedを解除
    }
    .header .header-inner {
        width: 100% !important; //サイト幅を解除
    }
    .page-title {
        margin-top: 0px !important; //固定ヘッダー時のマージンTOP分を解除
        width: 100% !important; //サイト幅を解除
    }
    .breadcrumbs-list {
        width: 100% !important; //サイト幅を解除
    }
    .global-nav {
        width: 100%; //サイト幅を解除
    }
    ------- ↑その他参考　---------*/
}

@page {
    size: A4;
    margin: 12.7mm 9.7mm;
}