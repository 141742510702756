@charset "utf-8";

/* =====================================================

 _top

===================================================== */

.top-main {
    margin-top: 90px;
    position: relative;
    @include mq(tb) {
        margin-top: 70px;
    }
    @include mq(sp) {
        margin-top: 60px;
    }
    &__catch {
        @include display-flex;
        @include justify-content-center;
        @include align-items-center;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        position: absolute;
        top: -30px;
        right: 0;
        bottom: 0;
        left: 0;
        width: 160px;
        height: 370px;
        margin: auto;
        border: 1px solid #fff;
        color: #fff;
        font-family: $f-mincho;
        font-size: 22px;
        letter-spacing: .16em;
        z-index: 1;
        @include mq(w1300) {
            width: 150px;
            height: 330px;
            font-size: 20px;
        }
        @include mq(w1100) {
            width: 130px;
            height: 300px;
            padding: 20px;
            font-size: 18px;
        }
        @include mq(tb) {
			top: -20px;
            width: 110px;
            height: 240px;
            padding: 15px 0 10px;
            font-size: 15px;
        }
    }
    .slider{
        overflow: hidden;
    }
	&__photo{
		&__item{
			width: 50%;
			height: 650px;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: 50% 50%;
			float: right;
			&:first-child{
				float: left;
			}
			@include mq(w1600){
				height: 40vw;
			}
			@include mq(sp){
				width: 100vw;
				height: 60vw;
				float: none;
				&:first-child{
					float: none;
				}
			}
			&--01-1{
				background-image: url(../img/top/main01-1.jpg);
			}
			&--01-2{
				background-image: url(../img/top/main01-2.jpg);
			}
			&--02-1{
				background-image: url(../img/top/main02-1.jpg);
			}
			&--02-2{
				background-image: url(../img/top/main02-2.jpg);
			}
			&--03-1{
				background-image: url(../img/top/main03-1.jpg);
			}
			&--03-2{
				background-image: url(../img/top/main03-2.jpg);
			}
		}
	}
    //スライダードット
    .slick-dots {
        position: absolute;
        bottom: 30px;
        @include mq(tb) {
            bottom: 10px;
        }
        li {
            width: 10px;
            height: 10px;
            margin: 0 10px;
			@include mq(sp) {
				margin: 0 5px;
			}
            button {
                width: 100%;
                height: 100%;
                background-color: #789;
                @include border-radius( 50% );
                &::before {
                    display: none;
                }
            }
            &.slick-active {
                button {
                    background-color: #fff;
                }
            }
        }
    }
    .slick-dotted.slick-slider {
        margin-bottom: 0!important;
    }
    .slick-track {
        @include mq(sp) {
            height: 200px;
        }
    }
}


.top-lead {
    padding: 90px 20px 100px;
    @include mq(tb) {
        padding: 40px 40px 60px;
    }
    @include mq(sp) {
        padding: 30px 20px 30px;
    }
    &__inner {
        text-align: center;
    }
    &__container {
        display: inline-block;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        height: 355px;
        margin: 0 auto 50px;
        @include mq(tb) {
            margin: 0 auto 30px;
            height: auto;
            -webkit-writing-mode: horizontal-tb;
            -ms-writing-mode: lr-tb;
            writing-mode: horizontal-tb;
			display: block;
			position: relative;
        }
        @include mq(sp) {
            margin: 0 auto 20px;
        }
    }
    &__catch {
        margin-left: 20px;
        color: #000;
        font-size: 21px;
        font-family: $f-mincho;
        text-align: left;
        line-height: 2.1;
		letter-spacing: 0.05em;
        @include mq(w1400) {
            font-size: 21px;
            line-height: 1.9;
        }
        @include mq(w1200) {
            font-size: 19px;
        }
        @include mq(tb) {
            margin-bottom: 10px;
            margin-left: 0;
            -webkit-writing-mode: horizontal-tb;
            -ms-writing-mode: lr-tb;
            writing-mode: horizontal-tb;
            line-height: 2.1;
        }
        @include mq(tb02) {
			font-size: 18px;
        }
        @include mq(sp) {
            margin-bottom: 10px;
            font-size: 17px;
			line-height: 1.7;
			text-align: justify;
        }
    }
    &__text {
        margin-left: 40px;
        color: #000;
        font-size: 1.6rem;
        font-family: $f-mincho;
        text-align: justify;
        text-justify: inter-ideograph;
        line-height: 2.7;
        @include mq(w1400) {
            line-height: 2.3;
			font-size: 15px;
        }
        @include mq(w1200) {
            margin-left: 20px;
            font-size: 15px;
            line-height: 2.1;
        }
        @include mq(tb) {
            margin-bottom: 30px;
            margin-left: 0;
			width: calc( 100% - 230px );
            font-size: 14px;
			line-height: 2.2;
        }
        @include mq(sp) {
            margin-bottom: 10px;
            padding: 0;
			width: auto;
            line-height: 1.9;
        }
    }
    &__image {
        @include mq(tb) {
			right: -20px;
			top: 105px;
            width: 220px;
			position: absolute;
        }
        @include mq(sp) {
			right: auto;
			top: auto;
			margin: 0 auto;
            width: 70%;
			position: relative;
        }
        img {
            max-width: 367px;
            width: 100%;
            @include mq(w1200) {
                max-width: 320px;
            }
        }
    }
    .btn-arrow {
        margin: 0 auto;
    }
}

.top-product {
    padding: 100px 0 120px;
    background-color: #f3f5f7;
    @include mq(tb) {
        padding: 80px 0;
    }
    @include mq(sp) {
        padding: 30px 0 50px;
    }
    &__inner {
        max-width: 1470px;
        margin: 0 auto;
        padding: 0 20px;
    }
		.product-list{
		margin-top: -3.5%;
		@include mq(w1400){
			margin-top: -3.6%;
		}
		@include mq(sp){
			margin: -4px -16px 0;
		}
		&__item{
			margin-top: 3.5%;
			margin-left: 3.5%;
			width: 22.375%;
			float: left;
			&:nth-child(4n-3){
				margin-left: 0;
			}
			@include mq(w1100){
				margin-top: 3.6%;
				margin-left: 0;
				width: 48.2%;
				&:nth-child(even){
					float: right;
				}
			}
			@include mq(sp){
				margin-top: 4px;
				width: calc( 50% - 2px );
			}
			&__link{
				display: block;
				background-color: #fff;
				&__text{
					padding: 15px 25px;
					font-size: 13px;
					line-height: 1.8;
					color: $cl-black;
					@include mq(w1400){
						padding: 20px 25px;
					}
					@include mq(sp){
						padding: 13px 15px;
						font-size: 12px;
						line-height: 1.6;
					}
					&__name{
						font-size: 17px;
						line-height: 1.5;
						color: $cl-black;
						@include mq(w1400){
							font-size: 1.214vw;
						}
						@include mq(w1100){
							font-size: 17px;
						}
						@include mq(sp){
							font-size: 15px;
							line-height: 1.2;
							text-align: justify;
						}
					}
				}
				&__btn{
					width: auto;
					height: 60px;
					font-size: 15px;
					line-height: 59px;
					color: #fff;
					font-family: $f-noto;
					font-weight: 400;
					text-align: center;
					background-color: $cl-black;
					background-image: url(../img/common/arrow_white.svg);
					background-repeat: no-repeat;
					background-size: 9px auto;
					background-position: right 25px top 50%;
					display: block;
					transition: 0.5s;
					@include mq(sp){
						height: 45px;
						font-size: 13px;
						line-height: 44px;
						background-position: right 15px top 50%;
					}
				}
				&:hover{
					.product-list__item__link__btn{
						background-color: $cl-gray;
						background-position: right 15px top 50%;
						@include mq(tb){
							background-color: $cl-black;
							background-position: right 25px top 50%;
						}
						@include mq(sp){
							background-position: right 15px top 50%;
						}
					}
				}
			}
		}
	}   
}

.top-knowledge {
    &__head {
        height: 550px;
        background-image: url("../img/top/knowledge_bg.jpg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: relative;
		text-align: right;
        @include mq(w1500) {
            height: 37vw;
        }
        @include mq(w1200) {
            height: 440px;
        }
        @include mq(tb) {
            height: 365px;
        }
        @include mq(sp) {
            height: 255px;
        }
        &::after {
            content: "";
            position: absolute;
            right: 0;
            bottom: -30px;
            left: 0;
            width: 70px;
            height: 30px;
            margin: 0 auto;
            border: 35px solid transparent;
            border-top: 30px solid #000;
            border-bottom: none;
            @include mq(tb) {
                bottom: -25px;
                height: 25px;
                border-top-width: 25px;
            }
            @include mq(sp) {
                bottom: -20px;
                width: 50px;
                height: 20px;
                border-width: 25px;
                border-top-width: 20px;
            }
        }
        &__inner {
            padding: 90px 100px 0 0;
            -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
			text-align: left;
			display: inline-block;
            @include mq(w1500) {
                padding: 6vw 6.5vw 0 0;
            }
            @include mq(tb) {
                padding: 50px 50px 0 0;
            }
            @include mq(sp) {
                padding: 20px 20px 0 0;
            }
        }
        &__catch {
            margin-left: 12px;
            color: #fff;
            font-size: 3rem;
            font-family: $f-mincho;
            line-height: 2;
            letter-spacing: .1em;
            @include mq(tb) {
                font-size: 2.4rem;
            }
            @include mq(sp) {
				margin-left: 8px;
                font-size: 2rem;
                line-height: 1.8;
            }
        }
        &__text {
            margin-top: 2px;
            color: #fff;
            font-size: 1.8rem;
            font-family: $f-mincho;
            line-height: 2.6;
            letter-spacing: .1em;
            @include mq(tb) {
                font-size: 1.6rem;
            }
            @include mq(sp) {
                font-size: 1.3rem;
                line-height: 2.2;
            }
        }
    }
    &__body {
        padding: 100px 50px 120px;
        @include mq(tb) {
            padding: 50px 20px 60px;
        }
        @include mq(sp) {
            padding: 30px 20px;
        }
        &__inner {
            max-width: 1470px;
            margin: 0 auto;
        }
        &__list {
            @include display-flex;
            margin-bottom: 60px;
            @include mq(tb) {
                margin-bottom: 30px;
            }
            @include mq(sp) {
                @include flex-wrap;
                margin-bottom: 20px;
            }
            &__item {
                width: calc((99.9% - (55px * 2)) / 3);
                @include mq(tb) {
                    width: calc((99.9% - (20px * 2)) / 3);
                }
                @include mq(sp) {
                    width: 100%;
                }
                &:not(:last-child) {
                    margin-right: 55px;
                    @include mq(tb) {
                        margin-right: 20px;
                    }
                    @include mq(sp) {
                        margin-right: 0;
                        margin-bottom: 25px;
                    }
                }
                &__image {
                    margin-bottom: 10px;
                }
                &__title {
                    margin-bottom: 8px;
                    color: #000;
                    font-size: 2.2rem;
                    font-family: $f-mincho;
                    letter-spacing: .08em;
                    @include mq(sp) {
						margin-bottom: 5px;
                        font-size: 2rem;
                    }
                    span {
                        font-size: 1.4rem;
                    }
                }
                &__text {
                    color: #000;
                    font-size: 1.5rem;
                    text-align: justify;
                    text-justify: inter-ideograph;
                    line-height: 1.8;
                    @include mq(sp) {
						font-size: 14px;
                    }
                }
            }
        }
    }
}

.top-bnr {
    &__list {
        @include display-flex;
        @include mq(tb) {
            @include flex-wrap;
        }
        &__item {
            width: 50%;
            @include mq(tb) {
                width: 100%;
            }
            &.item01 {
                margin-bottom: 1px;
            }
            &__link {
                @include display-flex;
                @include align-items-center;
                height: 380px;
                padding-left: 80px;
                background-color: #123;
                background-repeat: no-repeat;
                background-position: right center;
                background-size: cover;
                @include mq(tb) {
                    height: 280px;
                    padding-left: 50px;
                }
                @include mq(sp) {
                    height: 170px;
                    padding-left: 20px;
                }
                .item01 & {
                    background-image: url("../img/top/bnr_bg01.png");
                }
                .item02 & {
                    background-image: url("../img/top/bnr_bg02.png");
                }
                &:hover {
                    background-color: $cl-black;
                    .top-bnr__list__item__button {
                        background-image: url(../img/common/arrow_black.svg);
                        background-position: center right 10px;            
                        color: $cl-black;           
                    }
                }
            }
            &__body {
                margin-top: -10px;
                @include mq(sp) {
                    margin-top: 0;
                }
            }
            &__title {
                margin-bottom: 5px;
                color: #fff;
                font-size: 3.2rem;
                font-family: $f-mincho;
                line-height: 1.5;
                letter-spacing: .18em;
                @include mq(tb) {
                    font-size: 2.8rem;
                }
                @include mq(sp) {
					margin-bottom: 2px;
                    font-size: 2.2rem;
                }
            }
            &__text {
                margin-bottom: 20px;
                color: #fff;
                font-size: 1.5rem;
                font-family: $f-mincho;
                line-height: 2;
                letter-spacing: .18em;
                @include mq(tb) {
                    font-size: 1.4rem;
                    line-height: 1.8;
                }
                @include mq(sp) {
                    margin-bottom: 10px;
                    font-size: 1.3rem;
					line-height: 1.7;
					letter-spacing: 0.05em;
                }
            }
            &__button {
                display: block;
                max-width: 190px;
                padding: 10px;
                background-color: #fff;
                background-image: url(../img/common/arrow_navy.svg);
                background-size: 7px auto;
                background-repeat: no-repeat;
                background-position: center right 15px;            
                color: #123;
                font-size: 1.3rem;
                font-family: $f-noto;
                text-align: center;
                letter-spacing: .1em;
                transition: .3s;
                @include mq(sp) {
                    width: 170px;
                    padding: 8px;
                }
            }
        }
    }
}

.top-insta {
    padding: 100px 60px 0;
    @include mq(tb) {
        padding: 60px 40px 0;
    }
    @include mq(sp) {
        padding: 20px 20px 0;
    }
    &__inner {
        max-width: 1730px;
        margin: 0 auto;
    }
    &__title {
        margin-bottom: 30px;
        padding-bottom: 45px;
        font-size: 3.4rem;
        line-height: 1.01;
        color: $cl-black;
        font-family: $f-mincho;
        font-weight: 400;
        letter-spacing: .12em;
        text-align: center;
        position: relative;
        &::before{
            content: '';
            left: calc( 50% - 10px );
            bottom: 0;
            width: 22px;
            height: 22px;
            background-image: url(../img/common/icon_insta_black.svg);
            background-repeat: no-repeat;
            background-size: 100%;
            position: absolute;
        }
        @include mq(tb){
            padding-bottom: 34px;
            font-size: 30px;
            &::before{
                content: '';
                left: calc( 50% - 9px );
                width: 18px;
                height: 18px;
            }
        }
        @include mq(sp){
            margin-top: 30px;
            margin-bottom: 16px;
            padding-bottom: 26px;
            font-size: 5.3vw;
            &::before{
                content: '';
                left: calc( 50% - 8px );
                width: 16px;
                height: 16px;
            }
        }    
    }
    &__list {
		width: 100%;
		position: relative;
        &__item { 
			margin-top: 3.2%;
			margin-left: 3.2%;
			width: 14%;
			overflow: hidden;
			position: relative;
			float: left;
			&:first-child{
				top: 0;
				left: 0;
				margin-left: 0;
				width: 31.1%;
				position: absolute;
			}
			&:nth-child(2){
				margin-left: 34.25%;
			}
			&:nth-child(6){
				margin-left: 34.25%;
			}
			&:nth-child(-n+5){
				margin-top: 0;
			}
			@include mq(sp){
				margin-top: 2%;
				margin-left: 0;
				width: 49%;
				float: right;
				&:nth-child(even){
					float: left;
				}
				&:first-child{
					top: auto;
					left: auto;
					margin-top: 0;
					margin-left: 0;
					width: 100%;
					position: relative;
				}
				&:nth-child(2){
					margin-left: 0;
				}
				&:nth-child(6){
					margin-left: 0;
				}
				&:nth-child(-n+5){
					margin-top: 2%;
				}
			}
			&::before{
				content: '';
				padding-top: 100%;
				display: block;
			}
			&__link{
				display: block;
				&:hover{
					@include mq(pc){
						opacity: 0.7;
					}
				}
			}
			img{
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
            }
        }
    }
}

