@charset "utf-8";

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato:700,900&display=swap');

//font
$f-noto:'Noto Sans JP',sans-serif !important;
$f-mincho:'Noto Serif JP','游明朝体', 'Yu Mincho', serif;
$f-lato: 'Lato', sans-serif !important;
$f-nomal: '游ゴシック','Yu Gothic','游ゴシック体','YuGothic','ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro',sans-serif !important;

//color
$cl-black: #000;
$cl-gray: #789;
$cl-bg-gray: #f3f5f7;
$cl-bg-d-gray: #e9eeee;
$cl-bg-dd-gray: #abc;